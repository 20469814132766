import * as React from 'react'
import {
  List,
  Datagrid,
  FunctionField,
  Link,
  TextInput,
  SelectInput,
  ReferenceField,
  useNotificationContext,
  useRefresh,
} from 'react-admin'
import {
  LabField,
  PatientField,
  StatusField,
} from '../fields/kitFields'
import { OrderItemsField } from '../fields/orderFields'
import { createRequisitions, hasRequisitions } from '../fields/helpers/requisitionsFieldsHelpers'

const RequisitionsDueFilters = [
  <TextInput label="Kit Id or tracking number" source="q" alwaysOn />,

  <SelectInput
    source="status"
    choices={[
      {
        id: 'new',
        name: 'New',
      },
      {
        id: 'inboundShippingLabelPurchased',
        name: 'Inbound Shipping Label Purchased',
      },
      {
        id: 'unregisteredPrepaid',
        name: 'Unregistered Prepaid',
      },
      {
        id: 'outToPatient',
        name: 'Out to Patient',
      },
      {
        id: 'shippedByPatient',
        name: 'Shipped By Patient',
      },
      {
        id: 'deliveredToLab',
        name: 'Delivered to Lab',
      },
      {
        id: 'pendingConfirmation',
        name: 'Pending Confirmation',
      },
      {
        id: 'testComplete',
        name: 'Test Complete',
      },
      {
        id: 'notifyComplete',
        name: 'Notify Complete',
      },
      {
        id: 'error',
        name: 'Error',
      },
    ]}
  />,
]

const RequisitionsDueList = (props) => {
  const notify = useNotificationContext()
  const refresh = useRefresh()
  return (
    <List {...props} filter={{ _order: 'DESC', requisitionFilter: 'noRequisition' }} filters={RequisitionsDueFilters}>
      <Datagrid bulkActionButtons={<></>}>
        <FunctionField
          style={{ width: 'fit-content' }}
          label="KitId"
          render={(record) => <Link to={`/kits/${record.id}/show`}>{record.kitId}</Link>}
        />
        <FunctionField
          label="Order Details"
          render={() => {
            return (
              <>
                <PatientField />
                <ReferenceField source={'orderId'} reference="orders">
                  <OrderItemsField label="Order Items" />
                </ReferenceField>
              </>
            )
          }}
        />
        <StatusField label="Status" />
        <LabField label="Lab" />
        <FunctionField
          label="Action"
          render={(record) => {
            return (
              !hasRequisitions(record) && (
                <button className="btn btn-success text-white" onClick={() => createRequisitions(record, notify, refresh)}>
                  <span className="fa-solid fa-thumbs-up"></span> Create Test Requisitions
                </button>
              )
            )
          }}
        />
      </Datagrid>
    </List>
  )
}

export default RequisitionsDueList
