import { serverAddress } from '../../env'

const axios = require('axios')
let axios_client = undefined

export const client = () => {
  if (axios_client === undefined) {
    axios_client = axios.create({
      baseURL: serverAddress
    })

    axios_client.interceptors.request.use(function (config) {
      let token = localStorage.getItem("idToken");
      config.headers["Authorization"] = "Bearer " + token;
      return config;
    })
  }

  return axios_client
}

