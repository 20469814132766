import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import { Button, useListContext, useNotify, useRefresh, useUnselectAll } from 'react-admin'
import {
  canAssignKits,
  canCreateAllShippingLabels,
  canMarkAsFulfilled,
  canMarkAsManuallyFulfilled,
  isShippingLabelPurchased,
} from '../components/fields/helpers/orderFieldsHelpers'
import { assignKits, createAllShippingLabels, markAsShipped, printAllShippingLabels } from './progressOrderActions'

export const BulkActionsOrders = () => {
  const { selectedIds, data } = useListContext()
  const refresh = useRefresh()
  const notify = useNotify()
  const unselectAll = useUnselectAll('orders')
  const [actionMap, setActionMap] = useState({
    canAssignKits: [],
    canCreateAllShippingLabels: [],
    isShippingLabelPurchased: [],
    canMarkAsFulfilled: [],
    canMarkAsManuallyFulfilled: [],
  })

  useEffect(() => {
    setActionMap(updateActionMap(selectedIds, data))
  }, [selectedIds])

  return (
    <Box display={'flex'} flexDirection="row" alignItems={'center'} columnGap={'16px'} width="max-content">
      {/* Kit Creation/Assignment */}

      {actionMap.canAssignKits.length !== 0 && (
        <Button
          type="button"
          size="small"
          onClick={async () => {
            await assignKits(actionMap.canAssignKits, notify)
            refresh()
            unselectAll()
          }}
        >
          <p>
            <span className="fa fa-archive"></span> Assign Kits ({actionMap.canAssignKits.length})
          </p>
        </Button>
      )}

      {/* Create Shipping Label */}

      {actionMap.canCreateAllShippingLabels.length !== 0 && (
        <Button
          type="button"
          onClick={async () => {
            await createAllShippingLabels(actionMap.canCreateAllShippingLabels, notify)

            refresh()
            unselectAll()
          }}
        >
          <p>
            <span className="fa fa-barcode"></span> Create Shipping Labels ({actionMap.canCreateAllShippingLabels.length})
          </p>
        </Button>
      )}

      {/* Print Labels: Mark as Shipped */}

      {actionMap.isShippingLabelPurchased.length !== 0 && (
        <>
          <Button
            type="button"
            onClick={async () => {
              await printAllShippingLabels(actionMap.isShippingLabelPurchased, notify)
              refresh()
              unselectAll()
            }}
          >
            <p>
              <span className="fa fa-file-pdf-o"></span> Print All Labels ({actionMap.isShippingLabelPurchased.length})
            </p>
          </Button>

          <Button
            type="button"
            onClick={async () => {
              await markAsShipped(actionMap.isShippingLabelPurchased, notify, true, refresh)
              refresh()
              unselectAll()
            }}
          >
            <p>
              <span className="fa fa-envelope"></span> Mark as Shipped ({actionMap.isShippingLabelPurchased.length})
            </p>
          </Button>
        </>
      )}
      {/* Mark as Fulfilled (no shipping)  */}

      {actionMap.canMarkAsFulfilled.length !== 0 && (
        <Button
          type="button"
          onClick={async () => {
            await markAsShipped(actionMap.canMarkAsFulfilled, notify, false, refresh)

            refresh()
            unselectAll()
          }}
        >
          <p>
            <span className="fa fa-check"></span> Mark as Fulfilled ({actionMap.canMarkAsFulfilled.length})
          </p>
        </Button>
      )}

      {/* Mark as MANUALLY fulfilled (no shipping) */}
      {actionMap.canMarkAsManuallyFulfilled.length !== 0 && (
        <Button
          size="small"
          type="button"
          onClick={async () => {
            await markAsShipped(actionMap.canMarkAsManuallyFulfilled, notify, true, refresh)

            refresh()
            unselectAll()
          }}
        >
          <p>
            <span className="fa fa-check"></span> Mark as Manually Fulfilled ({actionMap.canMarkAsManuallyFulfilled.length})
          </p>
        </Button>
      )}
    </Box>
  )
}

const updateActionMap = (selectedIds, data) => {
  const actionMap = {
    canAssignKits: [],
    canCreateAllShippingLabels: [],
    isShippingLabelPurchased: [],
    canMarkAsFulfilled: [],
    canMarkAsManuallyFulfilled: [],
  }
  const filteredRecords = data.filter((d) => selectedIds.includes(d.id))

  filteredRecords.forEach((record) => {
    if (canAssignKits(record)) {
      actionMap.canAssignKits.push(record)
    }
    if (canCreateAllShippingLabels(record)) {
      actionMap.canCreateAllShippingLabels.push(record)
    }
    if (isShippingLabelPurchased(record)) {
      actionMap.isShippingLabelPurchased.push(record)
    }
    if (canMarkAsFulfilled(record)) {
      actionMap.canMarkAsFulfilled.push(record)
    }
    if (canMarkAsManuallyFulfilled(record)) {
      actionMap.canMarkAsManuallyFulfilled.push(record)
    }
  })

  return actionMap
}


