import * as React from 'react'
import { Layout } from 'react-admin'
import Menu from './Menu'
import { env } from '../env'
const MfhLayout = (props) => (
  <Layout {...props} menu={Menu}>
    {env !== 'prod' && <div className="version-banner">{env} version</div>}
    {props.children}
  </Layout>
)

export default MfhLayout
