// from react admin default example
// https://github.com/marmelab/react-admin/blob/master/examples/demo/src/layout/SubMenu.tsx

import * as React from 'react'
import { List, MenuItem, ListItemIcon, Typography, Collapse, Tooltip } from '@mui/material'
import { ExpandMore, ExpandLess } from '@mui/icons-material'
import { useSidebarState } from 'react-admin'

const SubMenu = (props) => {
  const { handleToggle, isOpen, name, icon, children, dense } = props

  const [sidebarIsOpen] = useSidebarState()
  const header = (
    <MenuItem onClick={handleToggle}>
      <ListItemIcon sx={{ minWidth: 5 }}>{isOpen ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
      <Typography variant="inherit" color="textSecondary">
        {name}
      </Typography>
    </MenuItem>
  )

  return (
    <div>
      {sidebarIsOpen || isOpen ? (
        header
      ) : (
        <Tooltip title={name} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          dense={dense}
          component="div"
          sx={{
            '& a': {
              transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
              paddingLeft: sidebarIsOpen ? 6.5 : 2,
            },
          }}
        >
          {children}
        </List>
      </Collapse>
    </div>
  )
}

export default SubMenu
