import { client } from './client'

const attachParams = (resource, params) => {
  if (Object.keys(params.filter).length) {
    const filters = []
    Object.keys(params.filter).forEach((key) => {
      switch (key) {
        case 'status':
          filters.push(`_status=${params.filter[key]}`)
          break
        case 'q':
          filters.push(`q=${params.filter[key]}`)
          break
        case '_order':
          filters.push(`_order=${params.filter[key]}`)
          break
        case 'requisitionFilter':
          filters.push(`requisitionFilter=${params.filter[key]}`)
          break
        case 'providerId':
          filters.push(`_provider=${params.filter[key]}`)
          break
        case 'shopifyOrderNumber':
          filters.push(`_shopifyOrderNumber=${params.filter[key]}`)
          break
        default:
          break
      }
    })

    resource += `?${filters.join('&')}`
  }

  return resource
}

const dataProvider = {
  getList: (resource, params) => {
    const newResource = attachParams(resource, params)
    return client()
      .get(newResource, params)
      .then((res) => {
        return res.data
      })
  },
  getOne: (resource, params) => {
    return client().get(`${resource}/${params.id}`, params)
  },
  getMany: (resource, params) => {
    // resolve api calls and then put responses in format expected by react-admin
    return client()
      .put(`${resource}/many`, params)
      .then((res) => {
        return {
          data: res.data,
        }
      })
  },
  getManyReference: (resource, _params) => {
    return client().get(resource)
  },
  update: (resource, params) => {
    return client().put(`${resource}/${params.id}`, params.data)
  },
  updateMany: (resource, params) => {
    return client().put(`${resource}/many`, params)
  },
  create: (resource, _params) => {
    return client().post(resource, _params.data)
  },
  delete: (resource, params) => {
    return client().delete(`${resource}/${params.id}`, params)
  },
  deleteMany: (resource, params) => {
    return params.ids.map((id) => {
      return client().put(`${resource}/${id}`, params)
    })
  },
}

export default dataProvider
