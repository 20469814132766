import { useRecordContext } from 'react-admin'
import { resultUri } from '../../../env'
import { client } from '../../../providers/data/client'
export const Title = () => {
  const record = useRecordContext()

  return record && <p>Kit #{record.kitId}</p>
}

var labTestAdviceCodeDetail = [
  {
    value: 'acNotTested',
    use: 'Kit not tested',
    label: 'Sample not yet submitted/tested.',
  },
  {
    value: 'acNSS',
    use: 'NSS and 8+ weeks since DoV',
    label:
      'No sperm seen in sample (observed count is less than ~100,000 sperm/mL), meeting the AUA standards for sterility. This is the expected result after a vasectomy.',
  },
  {
    value: 'acNSSRetest',
    use: 'NSS and < 8 weeks since DoV',
    label:
      'No sperm seen in sample. This test sample was submitted less than the recommended 8 weeks after vasectomy and therefore does not meet the AUA standards for sterility. It is recommended to wait at least a full 8 weeks after vasectomy to help ensure clearance of sperm, then send in another semen sample.',
  },
  {
    value: 'acRetest4Week',
    use: 'RARE',
    label:
      'RARE sperm seen in sample. Recommend to retest after at least another 4 weeks and 10 ejaculations. Clearance of sperm after vasectomy can take multiple months.',
  },
  {
    value: 'acRetest6Week',
    use: 'MODERATE and 8+ weeks since DoV ',
    label:
      'MODERATE sperm seen in sample. Recommend to retest after at least another 6 weeks and 15 ejaculations. Clearance of sperm after vasectomy can take multiple months. ',
  },
  {
    value: 'acRetest8Week',
    use: 'MODERATE, or HIGH and < 8 weeks since DoV ',
    label:
      'MODERATE/HIGH sperm seen in sample. Recommend to retest after at least another 8 weeks and 20 ejaculations. Clearance of sperm after vasectomy can take multiple months. ',
  },
  {
    value: 'acConsultProvider',
    use: 'HIGH and 8+ weeks since DOV',
    label: 'MODERATE/HIGH sperm seen in sample. Please contact your physician to discuss this result.',
  },
  {
    value: 'acConsultProviderSpecial',
    use: 'Manually select this option for other abnormal results, where patient should contact provider. Please enter specific instructions in provider notes field.',
    label: 'Please contact your physician to discuss this result.',
  },
  {
    value: 'acError',
    use: 'Testing error',
    label: 'An error occurred with the sample. Contact support for assistance.',
  },
]

export const AdviceCodeDetailTable = () => {
  return (
    <div>
      <p>
        <b>Advice Codes</b>
      </p>
      <p>
        <i>
          Select advice code appropriate for the test result. This is the messaging delivered to the patient when they retrieve
          results.
        </i>
      </p>
      <table className="info-table">
        <tr>
          <th>AdviceCode</th>
          <th>When to Use</th>
          <th>Result Sent to Patient</th>
        </tr>
        {labTestAdviceCodeDetail.map((code, index) => {
          return (
            <tr key={index}>
              <td>
                <b>{code.value}</b>
              </td>
              <td>{code.use}</td>
              <td>{code.label}</td>
            </tr>
          )
        })}
      </table>
    </div>
  )
}

export const formatResultRetrieval = (kit) => {
  // Generate link to result retrieval site, which is:
  //
  //  resultRetrievalHost + '#' + hash
  //
  //  where 'hash' is:
  //    base64encoded(
  //      {
  //        kitId: kitId,
  //        resultRetrievalCode: resultRetrievalCode
  //      }
  //    )
  //

  var retrievalHash = btoa(
    JSON.stringify({
      kitId: kit.kitId,
      resultRetrievalCode: kit.resultRetrievalCode,
    })
  )
  var retrievalUrl = resultUri + '#' + retrievalHash
  // Show the code, then link
  return (
    <p>
      {kit.resultRetrievalCode}&nbsp;&nbsp;
      <a href={retrievalUrl} target="_blank" rel="noreferrer">
        <span className="fa-solid fa-address-card" aria-hidden="true"></span>&nbsp;Results
      </a>
    </p>
  )
}

export const fetchResultImages = async (kitId) => {
  const resultImages = await client().get(`kits/${kitId}/artifacts`)
  return resultImages.data

}
