import { JSONToHTMLTable } from '@kevincobain2000/json-to-html-table'
import * as React from 'react'
import { Show, SimpleShowLayout, TextField, DateField, FunctionField } from 'react-admin'
import { ProviderField } from '../fields/kitFields'
import { Title } from './helpers/NotificationDetailsHelpers'

export default function NotificationDetails() {
  return (
    <Show title={<Title />}>
      <SimpleShowLayout spacing={2} className="details-page-layout">
        <TextField source="kitId" label="Kit Id" />
        <DateField source="requestedAt" label="Requested" />
        <TextField source="type" label="Type" />
        <TextField source="method" label="Method" />
        <TextField source="status" label="Status" />
        <ProviderField label="Provider Info" />
        <TextField source="faxNumber" label="Fax Number" />
        <TextField source="notes" label="Notes" />
        <TextField source="serviceProviderType" label="Service Provider Type" />
        <TextField source="serviceProviderId" label="Service Provider Id" />
        <TextField source="serviceProviderStatus" label="Service Provider Status" />
        <FunctionField
          source="serviceProviderData"
          label="Service Provider Data"
          render={(record) => {
            return <JSONToHTMLTable data={record.serviceProviderData} tableClassName="detail_table " />
          }}
        />
      </SimpleShowLayout>
    </Show>
  )
}
