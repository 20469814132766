import React, { useState, useRef, useEffect } from 'react'

const Carousel = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [startPosition, setStartPosition] = useState(null)
  const [isDragging, setIsDragging] = useState(false)
  const containerRef = useRef(null)

  useEffect(() => {
    const handleResize = () => {
      containerRef.current.style.transform = `translateX(${-currentIndex * 100}%)`
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [currentIndex])

  const handleStart = (position) => {
    setStartPosition(position)
    setIsDragging(true)
  }

  const handleMove = (position) => {
    if (!isDragging || startPosition === null) return
    const movePosition = position - startPosition
    containerRef.current.style.transform = `translateX(${-currentIndex * 100 + (movePosition / window.innerWidth) * 100}%)`
  }

  const handleEnd = (endPosition) => {
    setIsDragging(false)
    if (startPosition === null) return

    const difference = endPosition - startPosition

    if (difference > 50 && currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1)
    } else if (difference < -50 && currentIndex < items.length - 1) {
      setCurrentIndex((prev) => prev + 1)
    } else {
      containerRef.current.style.transform = `translateX(${-currentIndex * 100}%)`
    }

    setStartPosition(null)
  }

  const handleMouseDown = (e) => handleStart(e.clientX)
  const handleTouchStart = (e) => handleStart(e.touches[0].clientX)

  const handleMouseMove = (e) => handleMove(e.clientX)
  const handleTouchMove = (e) => handleMove(e.touches[0].clientX)

  const handleMouseUp = (e) => handleEnd(e.clientX)
  const handleTouchEnd = (e) => handleEnd(e.changedTouches[0].clientX)

  const handlePrev = () => currentIndex > 0 && setCurrentIndex((prev) => prev - 1)
  const handleNext = () => currentIndex < items.length - 1 && setCurrentIndex((prev) => prev + 1)

  return (
    <div className="carousel">
      <div
        className="carousel-container"
        ref={containerRef}
        style={{
          transform: `translateX(${-currentIndex * 100}%)`,
          transition: isDragging ? 'none' : 'transform 0.3s ease',
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={() => isDragging && handleEnd(startPosition)}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {items.map((item, index) => (
          <div className="carousel-item" key={index}>
            {item}
          </div>
        ))}
      </div>
      <button className="carousel-arrow left-arrow" onClick={handlePrev} disabled={currentIndex === 0}>
        &#9664;
      </button>
      <button className="carousel-arrow right-arrow" onClick={handleNext} disabled={currentIndex === items.length - 1}>
        &#9654;
      </button>
    </div>
  )
}

export default Carousel
