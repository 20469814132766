import moment from 'moment'
import * as React from 'react'
import { Show, SimpleShowLayout, TextField, FunctionField, ReferenceField, ReferenceArrayField, Datagrid } from 'react-admin'
import { OutBoundShipmentTracking, ShopifyRawData, Title } from './helpers/orderDetailHelpers'
import { fromTimestamp, labTestResultChoices } from '../fields/helpers/kitFieldsHelpers'

export default function OrderDetails() {
  return (
    <Show title={<Title />}>
      <SimpleShowLayout spacing={2} className="details-page-layout">
        <TextField label="Order #" source="shopifyOrderNumber" />
        <TextField label="MFH Status" source="status" />
        <FunctionField label="Outbound Shipping Needed?" render={(record) => (record.noOutboundShipping ? 'No' : 'Yes')} />
        <FunctionField
          label="Outbound Shipping Label"
          render={(record) =>
            record.shippingLabelUrl && (
              <a href={record.shippingLabelUrl} target="_blank" rel="noreferrer">
                <span className="fa-solid fa-file-pdf" aria-hidden="true"></span>&nbsp;PDF
              </a>
            )
          }
        />
        <FunctionField label="Outbound Ship Date" render={(record) => moment(record.shippingDate).local().format('LLLL')} />
        <OutBoundShipmentTracking label={'Outbound Shipment Tracking'} />

        <TextField label="Outbound Shipment Tracking Number" source="shippingTrackingNumber" />
        <TextField label="Order Notes" source="notes" />
        <FunctionField label="Prepaid Kit IDs"
          render={(record) =>
            record.prepaidKitIds && record.prepaidKitIds.length && (
             <span>{record.prepaidKitIds.at(0)} .. {record.prepaidKitIds.slice(-1)} ({record.prepaidKitIds.length})</span>
            )} />
        <ReferenceArrayField label="Kits" source={'kitIds'} reference="kits">
          <Datagrid
            empty={<div>This order does not have kits assigned.</div>}
            rowClick={false}
            sx={{
              // '& .RaDatagrid-checkbox': {
              //   display: 'none',
              // },
              '& .RaDatagrid-headerRow .MuiTableCell-paddingCheckbox.RaDatagrid-headerCell': { display: 'none' },
              '& .RaDatagrid-row > .MuiTableCell-paddingCheckbox': { display: 'none' },
              '& .RaDatagrid-rowCell, & .RaDatagrid-headerCell': { border: '1px solid black' },
            }}
          >
            <TextField source="kitId" />
            <TextField source="status" />
            <FunctionField
              source="inboundShippingLabelUrl"
              label="Inbound Shipping Label"
              render={(record) => {
                return record.inboundShippingLabelUrl ? (
                  <a href={record.inboundShippingLabelUrl} target="_blank" rel="noreferrer">
                    <span class="fa fa-file-pdf" aria-hidden="true"></span>&nbsp;PDF
                  </a>
                ) : (
                  <p>None</p>
                )
              }}
            />
            <FunctionField
              label="Lab Test Result"
              render={(record) => {
                const result = labTestResultChoices.filter((r) => r.value === record.labTestResult)
                return result.length !== 0 ? result[0].label : ''
              }}
            />
            <TextField source="labTestResult" />
          </Datagrid>
        </ReferenceArrayField>

        <ReferenceArrayField label="Prepaid Kits" source={'prepaidKitIds'} reference="kits">
          <Datagrid
            empty={<div>This order does not have prepaid kits assigned.</div>}
            rowClick={false}
            sx={{
              // '& .RaDatagrid-checkbox': {
              //   display: 'none',
              // },
              '& .RaDatagrid-headerRow .MuiTableCell-paddingCheckbox.RaDatagrid-headerCell': { display: 'none' },
              '& .RaDatagrid-row > .MuiTableCell-paddingCheckbox': { display: 'none' },
              '& .RaDatagrid-rowCell, & .RaDatagrid-headerCell': { border: '1px solid black' },
            }}
          >
            <TextField source="kitId" />
            <TextField source="status" />
            <FunctionField
              source="inboundShippingLabelUrl"
              label="Inbound Shipping Label"
              render={(record) => {
                return record.inboundShippingLabelUrl ? (
                  <a href={record.inboundShippingLabelUrl} target="_blank" rel="noreferrer">
                    <span class="fa fa-file-pdf" aria-hidden="true"></span>&nbsp;PDF
                  </a>
                ) : (
                  <p>None</p>
                )
              }}
            />
            <FunctionField
              label="Lab Test Result"
              render={(record) => {
                const result = labTestResultChoices.filter((r) => r.value === record.labTestResult)
                return result.length !== 0 ? result[0].label : ''
              }}
            />
            <TextField source="labTestResult" />
          </Datagrid>
        </ReferenceArrayField>
        <ShopifyRawData />
      </SimpleShowLayout>
    </Show>
  )
}
