// Parse the tracking number, return a url to:
//  USPS
//  UPS
//  FedEx
//  Google (default)
//  or, empty string if no trackingNumber
export const trackingUrl = (trackingNumber) => {

    // Regex patterns for different carriers
    const uspsPattern = /^(94|95|92|93|94)[0-9]{20,22}$/
    const upsPattern = /^1Z[0-9A-Z]{16}$/
    const fedexPattern = /^[0-9]{12,15}$/

    // No tracking number = return empty url
    if ( ! trackingNumber.length || parseInt(trackingNumber) === 0 ) {
        return ''
    }

    // Identify the carrier based on the tracking number pattern
    let carrier = null
    if (uspsPattern.test(trackingNumber)) {
        carrier = 'USPS'
    } else if (upsPattern.test(trackingNumber)) {
        carrier = 'UPS'
    } else if (fedexPattern.test(trackingNumber)) {
        carrier = 'FedEx'
    } else {
        carrier = 'Unknown'
    }

    // Generate the tracking URL based on the carrier
    let trackingURL = ''
    switch (carrier) {
    case 'USPS':
        trackingURL = `https://tools.usps.com/go/TrackConfirmAction?tLabels=${trackingNumber}`
        break
    case 'UPS':
        trackingURL = `https://wwwapps.ups.com/WebTracking/track?trackNums=${trackingNumber}`
        break
    case 'FedEx':
        trackingURL = `https://www.fedex.com/apps/fedextrack/?tracknumbers=${trackingNumber}`
        break
    case 'Unknown':
        console.log(`Unknown carrier ... using Google Search for tracking "${trackingNumber}"`)
        trackingURL = `https://www.google.com/search?q=${trackingNumber}`
        break
    }
    return trackingURL
}
