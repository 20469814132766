import { Box, Typography } from '@mui/material'
import { CreateButton } from 'react-admin'

const Empty = ({ text = 'No Data Found...', create = false, CreateComponent }) => {
  return (
    <Box textAlign="center" width={'100%'} height={'100%'} mt={'20%'}>
      <Typography variant="p" fontSize={'1.5rem'} paragraph>
        {text}
      </Typography>
      {create && (
        <>
          <Typography variant="body1">Create?</Typography>
          {CreateComponent ? <CreateComponent /> : <CreateButton />}
        </>
      )}
    </Box>
  )
}

export default Empty
