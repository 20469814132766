import { labTestSampleGradeChoices } from '../components/fields/helpers/kitFieldsHelpers'
import { client } from '../providers/data/client'
import { dataURLtoBlob } from './media'

function getCurrentDateForPrompt() {
  var d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [month, day, year].join('/')
}

export const isStatus = (queryStatus, kit) => {
  if (Array.isArray(queryStatus)) {
    return queryStatus.indexOf(kit.status) >= 0
  }
  return kit.status === queryStatus
}

export const isKitPrepaid = (queryStatus, kit) => {
  if (kit.sku && kit.sku.includes('MFHPPRK')) return isStatus(queryStatus, kit)

  return false
}

export const canGetRegLabel = (kit) => {
  return kit.sku === 'MFHPPRK'
}

export const canMarkAsNotifyComplete = (kit) => {
  return isStatus('testComplete', kit)
}

export const isTestComplete = (kit) => {
  return kit.labTestedAt > 0
}

export const canTest = (kit) => {
  return isStatus(['outToPatient', 'shippedByPatient', 'deliveredToLab'], kit)
}

export const providerHasBeenNotified = (kit) => {
  // One or more providerNotificationIds
  return Array.isArray(kit.providerNotificationIds) && kit.providerNotificationIds.length
}

export const canReNotifyPatient = (kit) => {
  // Test must be complete
  //  and
  // Patient ALREADY notified
  //  and
  // Status == 'testComplete' (user must revert status from 'notifyComplete' to re-notify patient)
  return kit.labTestedAt > 0 && patientHasBeenNotified(kit) && isStatus('testComplete', kit)
}

export const canNotifyProvider = (kit) => {
  // Test must be complete
  //  and
  // Status == 'testComplete' (user must revert status from 'notifyComplete' to re-notify patient)
  return kit.labTestedAt > 0 && isStatus('testComplete', kit)
}

export const patientHasBeenNotified = (kit) => {
  // Patient notifications are found in:
  //
  //  (a) kit.values.patientNotifications (array, and length > 0)
  //
  //  (b) kit.values.patientNotifiedAt is non-empty
  //
  var patientHasBeenNotified = false

  if (Array.isArray(kit.patientNotifications) && kit.patientNotifications.length) {
    patientHasBeenNotified = true
  }

  if (kit.patientNotifiedAt > 0) {
    patientHasBeenNotified = true
  }

  // If patient has been notified, then "renotify" is possible
  return patientHasBeenNotified
}

export const isPendingConfirmation = (kit) => {
  return isStatus('pendingConfirmation', kit)
}

const updateS3ImageMetadata = async ({ objectKeys, kitId }) =>
  await client().post(`/kits/artifact/setPatientIdCard`, {
    objectKeys: objectKeys,
    kitId: kitId,
  })

export const recordTestResult = async (testResults, patientIdCard, kit, notify, refresh, navigate) => {
  let kitStatus = 'pendingConfirmation'
  let testStatus = 'Unknown'
  let sampleGrade = 'NONE'
  let testText = 'Unknown test result'
  let testBy = localStorage.getItem('cognito_username') || 'unknown'
  let testAt = new Date()
  let specimenCollectionDate = testResults.collectionDate

  if (specimenCollectionDate === null) {
    // User canceled the prompt
    return
  }

  switch (testResults.result) {
    case 'NSS':
      testStatus = testResults.result
      testText = 'NSS (No Sperm Seen)'
      break
    case 'Positive':
      testStatus = testResults.result
      sampleGrade = testResults.sampleGrade
      testText =
        'Positive (Sperm Seen - ' + labTestSampleGradeChoices.find((item) => testResults.sampleGrade === item.value).label + ')'
      break
    case 'error':
      testStatus = testResults.result
      sampleGrade = 'ERROR'
      testText = 'error (Test or specimen error)'
      break
    default:
      let msg = 'Invalid test result: ' + testResults.result
      notify(msg, { type: 'info', undoable: false, multiLine: true })
      return
  }

  let notation =
    testResults.note + '\n' + 'Test Result: ' + testText + ' at ' + testAt.toLocaleString() + ' by user: ' + testBy + '. '
  const providerNotation = testResults.providerNotes

  notify('Recording test result for Kit #' + kit.kitId.toString() + ' ...', {
    type: 'info',
    undoable: false,
    multiLine: true,
  })

  try {
    const response = await client().get(`/kits/${kit.kitId}`)
    response.data.status = kitStatus
    response.data.specimenCollectionDate = specimenCollectionDate
    response.data.labTestResult = testStatus
    response.data.labTestSampleGrade = sampleGrade
    // Timestamp
    response.data.labTestedAt = testAt.getTime()
    response.data.labTestedBy = testBy
    response.data.notes = notation
    response.data.labTestProviderNotes = providerNotation

    response.data.id = kit.kitId.toString()

    let patientIdCardObjectKey = []

    if (testResults.images.length > 0) {
      notify(`Uploading Images.....`, {
        type: 'info',
        undoable: false,
        multiLine: true,
      })

      const imagesObjectKeys = testResults.images.map((img, index) => {
        const objKey = `${response.data.id}/resultImages/result-${crypto.randomUUID()}.png`

        // This step is for img with data-url
        if (patientIdCard.includes(img)) {
          patientIdCardObjectKey.push(objKey)
        }

        return objKey
      })

      const imageBuffers = await Promise.all(testResults.images.map(async (image) => await dataURLtoBlob(image)))

      const resData = await client().post(`/kits/artifacts-url`, { imagesObjectKeys })

      await Promise.all(
        resData.data.signedUrls.map(async (url, index) => {
          notify(`Saving Image ${index + 1}`, {
            type: 'info',
            undoable: false,
            multiLine: true,
          })

          await fetch(url, {
            method: 'PUT',
            body: imageBuffers[index],
            headers: {
              'Content-Type': 'image/png',
            },
          })
        })
      )

      notify(`Images uploaded successfully.....`, {
        type: 'info',
        undoable: false,
        multiLine: true,
      })

      response.data.resultImages = Array.isArray(response.data.resultImages)
        ? [...response.data.resultImages, imagesObjectKeys]
        : imagesObjectKeys
    }

    // Checking if already uploaded images are selected and pushing their object keys to patientIdCardObjectKey
    const regex = /result-[\w-]+\.png/
    patientIdCard.forEach((i) => {
      const matchResult = i.match(regex)
      if (matchResult) {
        patientIdCardObjectKey.push(`${response.data.id}/resultImages/${matchResult[0]}`)
      }
    })

    await updateS3ImageMetadata({
      objectKeys: patientIdCardObjectKey ?? [],
      kitId: response.data.id,
    })

    notify(`Saving Test Results....`, {
      type: 'info',
      undoable: false,
      multiLine: true,
    })

    await client().put(`/kits/${response.data.id}`, response.data)

    refresh()

    // Normal notification
    notify(`${kit.kitId.toString()} Test Recorded: ${testText}`, {
      type: 'success',
      undoable: false,
      multiLine: true,
    })
  } catch (err) {
    notify(`A problem occurred, please try again`, {
      type: 'error',
      undoable: false,
      multiLine: true,
    })
    console.log(err)
  }
}

export const getLabTestResultPdf = async (kit, notify) => {
  try {
    const response = await client().get(`/kits/${kit.kitId.toString()}/resultPdf`)

    let d = response.data.response
    if (d.url) {
      window.open(d.url)
    } else {
      let errorMessage = JSON.stringify(d)
      notify(errorMessage, {
        type: 'error',
        undoable: false,
        multiLine: true,
      })
    }
  } catch (error) {
    console.error(error)
    var errorMessage = 'An error occured trying to retrieve Lab Test Result PDF for Kit ' + kit.kitId.toString()

    if (error.data.response) {
      // Get specific data from the response
      errorMessage = error.data.response.message
    }

    notify(errorMessage, { type: 'error', multiLine: true, undoable: false })
  }
}

export const canNotifyPatient = (kit) => {
  // Test must be complete
  //  and
  // Patient not yet notified
  //  and
  // Status == 'testComplete' (user must revert status from 'notifyComplete' to re-notify patient)
  return kit.labTestedAt > 0 && !patientHasBeenNotified(kit) && isStatus('testComplete', kit)
}

export const notifyPatient = async (prompt, kits, notify, refresh) => {
  if (prompt) {
    if (!window.confirm('Are you sure you want to re-send a secure notification link to this patient via text or email?')) {
      return
    }
  }

  const kitIds = kits.map((kit) => kit.kitId)
  // Initial UI notification ...
  notify('Generating text/email notification for Kits ' + kitIds.join(',') + ' ...', {
    type: 'info',
    multiLine: true,
    undoable: false,
  })

  try {
    const response = await client().post(`/kits/notifyPatient`, { kitIds })

    var notifyResponse = response.data

    const notifyMessagesArray = notifyResponse.success.map((notification) => {
      var notifyMessage = `KitIds: ${notification.kitId}\nNotification Result: ${
        notification.success ? 'Success' : 'Error'
      }\nMethod: ${notification.method}`

      if (notification.method === 'Text') {
        // Text message
        notifyMessage = notifyMessage + '\nText Number: ' + notification.textNumber + '\n'
      } else {
        // Email
        notifyMessage =
          notifyMessage +
          `Email: ${notification.emailTo}\nEmail (cc): ${notification.emailCc}\nEmail (bcc): ${notification.emailBcc}\n`
      }

      // Note?
      if (notification.note) {
        notifyMessage = notifyMessage + 'Note: ' + notification.note + '\n'
      }
      return notifyMessage
    })
    // Show the notification. Include
    // the text '[Close]' as the last line so that the user
    // will click it to dismiss
    notify(notifyMessagesArray.join('\n'), {
      type: 'info',
      multiLine: true,
      undoable: false,
      autoHideDuration: 0,
    })

    refresh()
  } catch (error) {
    var errorMessage = 'A problem occurred:\n\n' + JSON.stringify(error.data)

    notify(errorMessage, {
      type: 'error',
      multiLine: true,
      undoable: true,
    }) && console.error(error)
  }
}

export const recordManualNotification = async (kit, notify, refresh) => {
  var method = prompt('How was notification sent?\n[Text, Email, Other]')
  if (!method) {
    return
  }

  // Validate
  if (['Email', 'Text', 'Other'].indexOf(method) < 0) {
    alert('Invalid method')
    return
  }

  var extraNote = prompt('Enter additional notes:')

  let patientNotifiedAt = new Date()
  let patientNotifiedMethod = method
  let patientNotifiedBy = localStorage.getItem('cognito_username') || 'unknown'

  let notation = `Patient Notified via ${patientNotifiedMethod} at ${patientNotifiedAt.toString()} by user: ${patientNotifiedBy}.\n`

  if (extraNote) {
    notation = notation + `\nManual Notification Notes: ${extraNote}\n`
  }

  // Initial UI notification ...
  notify('Recording manual notification for Kit #' + kit.kitId.toString() + ' ...', {
    type: 'info',
    multiLine: true,
    undoable: false,
  })
  try {
    const response = await client().get(`/kits/${kit.kitId.toString()}`)
    response.data.patientNotifiedAt = patientNotifiedAt.getTime()
    response.data.patientNotifiedMethod = patientNotifiedMethod
    response.data.patientNotifiedBy = patientNotifiedBy
    response.data.notes = response.data.notes ? response.data.notes + '\n' + notation : notation

    // Restangular object needs "id" to know which API endpoint to call
    response.data.id = kit.kitId.toString()
    await client().put(`kits/${response.data.id.toString()}`, response.data)
    refresh()
    notify(`${kit.kitId}\nPatient/Provider manual notification(s) recorded.`, {
      multiLine: true,
      type: 'success',
    })
  } catch (error) {
    notify(`A problem occurred, please try again`, {
      multiLine: true,
      type: 'error',
    })
  }
}

export const notifyProviderFax = async (kits, notify, refresh) => {
  var defaultProviderId = '00000000-0000-0000-0000-000000000000'
  // For kits already having provider notifications
  const anotherFaxRequest = []

  // For kits that have providers
  const validKits = []

  // For kits that have no providers
  const invalidKits = []

  // Extracting all kitIds from kits

  kits.forEach((kit) => {
    // If no providerId (or default), then we can't proceed
    if (!kit.providerId || kit.providerId === defaultProviderId) {
      invalidKits.push(kit.kitId)
    } else {
      validKits.push(kit)
    }

    // Do we already have one or more providerNotifications?
    if (Array.isArray(kit.providerNotificationIds) && kit.providerNotificationIds.length) {
      anotherFaxRequest.push(kit.kitId)
    }
  })

  // The provider
  var provider

  // This is a 'fax' notification
  var method = 'fax'

  // We'll need to use the "force=true"
  //  param to the POST if there are already some notifications requested
  var force = false

  // If no providerId (or default), then we can't proceed
  if (invalidKits.length > 0) {
    notify(
      'Unable to request fax -- no provider assigned to these kits.\n ' +
        invalidKits.join('\n') +
        '\nThe rest of the kits will be processed.',
      {
        type: 'error',
        multiLine: true,
        autoHideDuration: 0,
      }
    )
    if (validKits.length === 0) {
      return
    }
  }

  // Do we already have one or more providerNotifications?
  if (anotherFaxRequest.length > 0) {
    if (
      !window.confirm(
        'Some kits already has ' +
          ' provider notification(s) requested.\n\n' +
          'Are you sure you want to create another fax request?'
      )
    ) {
      return
    }
    // User has agreed to request another fax
    force = true
  }

  const validKitIds = validKits.map((kit) => kit.kitId)

  // Initial UI notification ...
  notify('Loading provider info for Kits ' + validKitIds.join(' , ') + ' ...', {
    type: 'info',
    multiLine: true,
  })

  // Make a GET to /providers/{providerId} to get info about the provider's prefs
  const providerNotifications = []

  const filteredValidKits = validKits.filter(async (kit) => {
    const response = await client().get(`/providers/${kit.providerId}`)

    provider = response.data

    // Check for the following detail in the provider:
    //  a Fax number
    //  preferences:
    //   faxNegativeResults
    //   faxPositiveResults
    if (!provider.fax || !provider.fax.length) {
      providerNotifications.push('No fax number found for provider "' + provider.displayName + '".')
      return false
    }

    if (provider.faxNegativeResults === 'No' && kit.labTestResult === 'NSS') {
      providerNotifications.push('Provider "' + provider.displayName + '" preferences prevent faxing Negative results.')
      return false
    }

    if (provider.faxNegativePositive === 'No' && kit.labTestResult === 'Positive') {
      providerNotifications.push('Provider "' + provider.displayName + '" preferences prevent faxing Positive results.')
      return false
    }

    return true
  })

  const filteredValidKitIds = filteredValidKits.map((kit) => kit.kitId)
  try {
    // If we get here, then we are ready to request a provider notification via POST
    const kitResponse = await client().post(`/kits/providernotification`, {
      kitIds: filteredValidKitIds,
      method,
      force,
    })

    var n = kitResponse.data

    // Show the notification. Include
    // the text '[Close]' as the last line so that the user
    // will click it to dismiss
    const faxNumbersArr = n.success.map((successNotification) => {
      if (successNotification) {
        return successNotification.faxNumber
      }
    })
    notify(
      'Requested fax to "' +
        faxNumbersArr.join(' , ') +
        '".  Check Notifications page for details.\nErrors:\n' +
        n.error.join('\n'),
      {
        type: 'success',
        multiLine: true,
      }
    )

    refresh()
  } catch (error) {
    var errorMessage = 'A problem occurred: ' + JSON.stringify(error.data)

    notify(errorMessage, {
      type: 'error',
      multiLine: true,
    })
  }
}

export const notifyProviderManual = async (kit, notify, refresh) => {
  var kitId = kit.kitId.toString()
  // var providerId = kit.providerId
  // var labTestResult = kit.labTestResult
  var providerNotificationIds = kit.providerNotificationIds

  // We'll need to use the "force=true"
  //  param to the POST if there are already some notifications requested
  var force = false

  // Do we already have one or more providerNotifications?
  if (Array.isArray(providerNotificationIds) && providerNotificationIds.length) {
    if (
      !window.confirm(
        'Kit already has ' +
          providerNotificationIds.length +
          ' provider notification(s) requested.\n\n' +
          'Are you sure you want to record a manual notification?'
      )
    ) {
      return
    }
    // User has agreed to request another fax
    force = true
  }

  let method = prompt('How was provider notified?\n[text, email, phone, manual]')
  if (!method) {
    return
  }

  // Validate
  if (['email', 'text', 'phone', 'manual'].indexOf(method) < 0) {
    alert('Invalid method')
    return
  }

  let notes = prompt('Enter additional notes:')
  if (!notes.length) {
    alert('Please enter notes.')
    return
  }

  // let patientNotifiedBy = localStorage.getItem('cognito_username') || 'unknown'

  // Initial UI notification ...
  notify('Recording manual provider notification for Kit #' + kitId + ' ...', {
    type: 'info',
    multiLine: true,
  })

  try {
    const response = await client().post(`/kits/providernotification`, {
      kitIds: [kit.id],
      method,
      force,
      notes,
    })
    // var n = response.data

    // Show the notification. Include
    // the text '[Close]' as the last line so that the user
    // will click it to dismiss
    notify('Recorded manual provider notification.  Check Notifications page for details.', {
      type: 'success',
      multiLine: true,
    })

    refresh()
  } catch (error) {
    var errorMessage = 'A problem occurred: \n\n' + JSON.stringify(error.data)

    notify(errorMessage, {
      type: 'error',
      multiLine: true,
    }) && console.error(error)
  }
}

export const getRegLabelPdf = async (kit, notify) => {
  try {
    const response = await client().get(`/kits/${kit.kitId}/regLabelPdf`)

    let d = response.data.response
    if (d.url) {
      window.open(d.url)
    } else {
      let errorMessage = JSON.stringify(d)
      notify(errorMessage, {
        type: 'error',
        multiLine: true,
      })
    }
  } catch (e) {
    console.error(e)
    var errorMessage = 'An error occured trying to retrieve registration label PDF for Kit ' + kit.kitId.toString()

    if (e.data.response) {
      // Get specific data from the response
      errorMessage = e.data.response.message
    }

    notify(errorMessage, {
      type: 'error',
      multiLine: true,
    })
  }
}

export const markAsNotifyComplete = async (kits, notify, refresh) => {
  let newStatus = 'notifyComplete'

  // Check for patient notifications
  const noPatientNotificationKits = []
  kits.forEach((kit) => {
    if (!patientHasBeenNotified(kit)) {
      noPatientNotificationKits.push(kit.kitId)
    }
  })

  if (noPatientNotificationKits.length > 0) {
    if (
      !window.confirm(
        `No Patient notification(s) found for Kits ${noPatientNotificationKits.join(' , ')}.\n\nMark as Notify Complete anyway?`
      )
    ) {
      return
    }
  }

  const noProviderNotified = []

  kits.forEach((kit) => {
    if (!providerHasBeenNotified(kit)) {
      noProviderNotified.push(kit.labTestResult)
    }
  })

  if (noProviderNotified.length > 0) {
    // Check for provider notifications
    if (
      !window.confirm(
        'No Provider notification(s) found.\n\n' +
          'Please confirm that Provider does not want notification for [' +
          noProviderNotified.join(' , ') +
          '] test result.\n\nMark as Notify Complete anyway?'
      )
    ) {
      return
    }
  }
  try {
    kits.forEach((kit) => (kit.status = newStatus))
    await client().put(`/kits/bulkUpdate`, { kits })
    refresh()
  } catch (e) {
    notify('A problem occurred, please try again', {
      type: 'error',
      multiLine: true,
    }) && console.error(e)
  }
}

export const revertStatus = async (newStatus, kit, notify, refresh) => {
  let actionDate = new Date()
  let username = localStorage.getItem('cognito_username') || 'unknown'
  let notation =
    "Kit status reverted from '" +
    kit.status +
    "' to '" +
    newStatus +
    "' at " +
    actionDate.toLocaleString() +
    ' by user: ' +
    username +
    '.\n'

  // Initial UI notification ...
  notify('Updating status for Kit #' + kit.kitId.toString() + ' to ' + newStatus + ' ...', {
    type: 'info',
    multiLine: true,
  })
  try {
    const response = await client().get(`/kits/${kit.kitId}`)

    response.data.status = newStatus

    // Restangular object needs "id" to know which API endpoint to call
    response.data.id = kit.kitId.toString()
    await client().put(`/kits/${kit.kitId}`, response.data)
    refresh()
  } catch (e) {
    notify('A problem occured, please try again', {
      type: 'error',
      multiLine: true,
    })
  }
}

export const createShippingLabel = async (kit, notify, refresh) => {
  try {
    const response = await client().post(`/kits/${kit.kitId}/createInboundShipping`)

    if (response) {
      refresh()
      return kit
    }
  } catch (e) {
    notify('A problem occurred, please try again', {
      type: 'error',
      multiLine: true,
    }) && console.error(e)
  }
}
