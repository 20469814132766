import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { useLogin, useNotify } from 'react-admin'
import AuthProvider from '../../providers/auth/cognitoProvider'
import { useNavigate, useLocation } from 'react-router-dom'

export default function LoginPage() {
  const location = useLocation()
  const navigate = useNavigate()
  const notify = useNotify()
  const login = useLogin()
  const [forgotPassword, setForgotPassword] = React.useState(false)
  const [confirmPassword, setConfirmPassword] = React.useState(false)
  const [changeTempPassword, setChangeTempPassword] = React.useState(false)
  const [accountInfo, setAccountInfo] = React.useState({})

  React.useEffect(() => {
    if (location.pathname === '/login' && localStorage.getItem('idToken') && localStorage.getItem('accessToken')) {
      navigate('/kits')
    }
  }, [location.pathname, navigate])

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    login({
      username: data.get('username'),
      password: data.get('password'),
    }).catch((error) => {
      if (error?.changeTempPassword) {
        setChangeTempPassword(true)
        setAccountInfo({ username: error.user.username, password: error.user.password })
        notify('Please set a new password to continue.')
      } else {
        notify('Invalid username or password.')
      }
    })
  }

  const handleForgotPassword = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    if (confirmPassword) {
      AuthProvider.confirmPassword({
        username: data.get('username'),
        code: data.get('code'),
        newPassword: data.get('new-password'),
      })
        .then(() => {
          notify('Password has been reset.')
          setConfirmPassword(false)
          setForgotPassword(false)
        })
        .catch((error) => notify(error))
      return
    }
    AuthProvider.forgotPassword({
      username: data.get('username'),
    })
      .then(() => {
        notify('A confirmation code has been sent to your registered email.')
        setConfirmPassword(true)
      })
      .catch((error) => notify(error))
  }

  const handleTempPasswordReset = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)

    login({
      newPassword: data.get('new_password'),
      username: accountInfo.username,
      password: accountInfo.password,
    })
      .then(() => {
        notify('Password has been reset successfully.')
        setChangeTempPassword(false)
        setAccountInfo({})
      })
      .catch((error) => notify(error.message || 'An error occurred'))
  }

  const SignInForm = () => {
    return (
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <TextField margin="normal" required fullWidth id="username" label="Username" name="username" autoFocus />
        <TextField margin="normal" required fullWidth name="password" label="Password" type="password" id="password" />
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          Sign In
        </Button>
        <Grid container>
          <Grid item xs>
            <Button type="button" variant="text" onClick={() => setForgotPassword(true)}>
              Forgot password?
            </Button>
          </Grid>
        </Grid>
      </Box>
    )
  }

  const ForgotPasswordForm = () => {
    return (
      <Box component="form" onSubmit={handleForgotPassword} sx={{ mt: 1 }}>
        <TextField margin="normal" required fullWidth id="username" label="Username" name="username" autoFocus />

        {confirmPassword ? (
          <>
            <TextField margin="normal" required fullWidth name="code" label="Code" id="code" />
            <TextField
              margin="normal"
              required
              fullWidth
              name="new-password"
              label="New Password"
              type="password"
              id="new-password"
            />
          </>
        ) : (
          <></>
        )}
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          {confirmPassword ? 'Reset Password' : 'Send Code'}
        </Button>
      </Box>
    )
  }

  const TempPasswordResetForm = () => {
    return (
      <Box component="form" onSubmit={handleTempPasswordReset} sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          name="new_password"
          label="New Password"
          type="password"
          id="new_password"
          helperText="Contains at least 1 number, 1 uppercase letter and 1 lowercase letter."
          autoFocus
        />
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          Set New Password
        </Button>
      </Box>
    )
  }

  return (
    <Container component="main" sx={{ height: '100vh' }} maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography textAlign={'center'} component="h1" variant="h5">
          MaleFromHome Admin
          <br />
          {forgotPassword ? 'Reset Password' : changeTempPassword ? 'Set New Password' : 'Sign in'}
        </Typography>
        {changeTempPassword ? <TempPasswordResetForm /> : forgotPassword ? <ForgotPasswordForm /> : <SignInForm />}
      </Box>
    </Container>
  )
}
