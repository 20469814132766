import * as React from 'react'
import {
  List,
  TextField,
  FunctionField,
  DatagridConfigurable,
  TopToolbar,
  SelectColumnsButton,
  TextInput,
  Link,
  FilterButton,
  ReferenceInput,
  SelectInput,
  ShowButton,
} from 'react-admin'
import { ProviderField, ToolTip } from '../fields/kitFields'
import RequestedField from '../fields/notificationFields'

const NotificationList = (props) => {
  const NotificationFilters = [
    <TextInput label="providerId" source="q" alwaysOn />,
    <ReferenceInput source="providerId" reference="providers">
      <SelectInput optionText={'displayName'} />
    </ReferenceInput>,
  ]
  return (
    <List
      {...props}
      actions={
        <TopToolbar>
          <FilterButton />
          <SelectColumnsButton />
        </TopToolbar>
      }
      filters={NotificationFilters}
      filter={{ _order: 'DESC' }}
    >
      <DatagridConfigurable bulkActionButtons={<></>}>
        <FunctionField
          style={{ width: 'fit-content' }}
          label="KitId"
          render={(record) => <Link to={`/kits/${record.id}/show`}>{record.kitId}</Link>}
        />
        <RequestedField label="Requested" />
        <TextField label="Type" source="type" />
        <TextField label="Method" source="method" />
        <TextField label="Status" source="status" />
        <ProviderField label="Provider Info" />
        <TextField label="Fax Number" source="faxNumber" />
        <ToolTip />
        <ShowButton label="Show"/>
      </DatagridConfigurable>
    </List>
  )
}

export default NotificationList
