import * as React from 'react'
import {
  List,
  TextField,
  ReferenceArrayField,
  FunctionField,
  SingleFieldList,
  DatagridConfigurable,
  TopToolbar,
  SelectColumnsButton,
  FilterButton,
  SelectInput,
  TextInput,
  useNotify,
  useRefresh,
} from 'react-admin'
import { Link } from 'react-router-dom'
import { BulkActionsOrders } from '../../utils/bulkProgressOrder'
import RefreshOrderButton from '../../utils/refreshOrdersButton'
import { ToolTip } from '../fields/kitFields'
import { OrderCustomerDetailField, OrderItemsField, OrderOutBoundShippingField, ProgressOrderActions } from '../fields/orderFields'
import Chip from '@mui/material/Chip'

const OrderList = (props) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const [loading, setLoading] = React.useState(false)
  const handleLoading = (bool) => setLoading(bool)

  const OrderFilters = [
    <TextInput label="Search" source="q" alwaysOn />,
    <SelectInput
      source="status"
      choices={[
        { id: 'new', name: 'New' },
        { id: 'kitsAssignedReadyToShip', name: 'Ready To Ship' },
        { id: 'shippingLabelPurchased', name: 'Shipping Label Purchased' },
        { id: 'shipped', name: 'Shipped' },
        { id: 'duplicate', name: 'Duplicate' },
        { id: 'canceled', name: 'Canceled' },
      ]}
    />,
  ]

  return (
    <List
      {...props}
      actions={
        <TopToolbar>
          <RefreshOrderButton setLoading={handleLoading} notify={notify} refresh={refresh} />
          <FilterButton />
          <SelectColumnsButton />
        </TopToolbar>
      }
      filter={{ _order: 'DESC' }}
      filters={OrderFilters}
    >
      <DatagridConfigurable isLoading={loading} bulkActionButtons={<BulkActionsOrders />}>
        <FunctionField
          label="Order #"
          render={(record) => <Link to={`/orders/${record.id}/show`}>{record.shopifyOrderNumber}</Link>}
        />
        <ToolTip />
        <FunctionField
          label="MFH Status"
          render={(record) => {
            if (record.status == 'new') {
              return (
                <Chip label={record.status} color="success" />
              )
            }
            return (
              <Chip label={record.status} />
            )
          }}
        />
        <OrderCustomerDetailField label="Customers" />
        <OrderItemsField label="Order Items" />
        <ReferenceArrayField source="kitIds" reference="kits">
          <SingleFieldList style={{ display: 'flex', flexFlow: 'column', rowGap: '10px' }}>
            <TextField source="id" />
          </SingleFieldList>
        </ReferenceArrayField>
        <FunctionField
          label="PPKits"
          render={(record) => {
            if (record.prepaidKitIds && record.prepaidKitIds.length)
              return (
                <span>
                  <b>{record.prepaidKitIds.length}</b>
                </span>
              )
          }}
        />
        <OrderOutBoundShippingField label="Outbound Shipping" />
        <ProgressOrderActions label="Actions" />
      </DatagridConfigurable>
    </List>
  )
}

export default OrderList
