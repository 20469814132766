// Vanilla axios client, w/o added auth header or base address
const axios = require('axios')
let vanilla_client = undefined

export const api2pdfclient = () => {
  if (vanilla_client === undefined) {
    vanilla_client = axios.create({
    })
  }

  return vanilla_client
}

