import { client } from '../../../providers/data/client'

export function hasRequisitions(record) {
  var req = record['testRequisition']
  return req && req.requisitionDate ? true : false
}

export async function createRequisitions(record, notify, refresh) {
  try {
    const response = await client().post(`/kits/${record.kitId}/testRequisition`)
    if (response) {
      notify('POST testRequisition successful for ' + record.kitId, {
        type: 'success',
        multiline: true,
      })
      refresh()
    }
  } catch (e) {
    var response = e.data
    var msg
    if (response && response.error && response.error.message) {
      msg = response.error.message
    } else if (response && response.message) {
      msg = response.message
    }
    notify('Error.\n' + msg, {
      type: 'error',
      multiline: true,
    }) && console.error(e)
  }
}
