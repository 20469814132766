import * as React from 'react'
// eslint-disable-next-line no-unused-vars
import { Admin, CustomRoutes, Resource, ListGuesser, EditGuesser } from 'react-admin'
import { Route } from 'react-router'
import cognitoProvider from './providers/auth/cognitoProvider'
import mfhDataProvider from './providers/data/mfhProvider'
import KitList from './components/lists/KitList'
import OrderList from './components/lists/OrderList'
import { KitEdit } from './components/editors/KitEdit'
import { OrderEdit } from './components/editors/OrderEdit'
import Layout from './layouts/Layout'
import ProviderList from './components/lists/ProviderList'
import NotificationList from './components/lists/NotificationList'
import RequisitionsDueList from './components/lists/RequisitionsDueList'
import OrderDetails from './components/details/orderDetails'
import KitDetails from './components/details/kitDetails'
import NotificationDetails from './components/details/NotificationDetails'
import ProviderDetails from './components/details/providerDetails'
import { ProviderEdit } from './components/editors/ProviderEdit'
import { ProviderCreate } from './components/create/ProviderCreate'
import LoginPage from './components/auth/LoginPage'
import ProviderAccessList from './components/lists/ProviderAccess'
import ProviderAccessDetail from './components/details/ProviderAccessDetail'
import { ProviderAccessEdit } from './components/editors/ProviderAccessEdit'

const App = () => (
  <Admin
    loginPage={LoginPage}
    requireAuth
    disableTelemetry
    authProvider={cognitoProvider}
    dataProvider={mfhDataProvider}
    layout={Layout}
  >
    <CustomRoutes>
      <Route path="orders" element={<Resource name="orders" list={OrderList} edit={EditGuesser} show={OrderDetails} />} />
      <Route path="orders/status/new" element={<Resource name="orders/status/new" list={OrderList} />} />
      <Route
        path="orders/status/kitsAssignedReadyToShip"
        element={<Resource name="orders/status/kitsAssignedReadyToShip" list={OrderList} />}
      />
      <Route
        path="orders/status/shippingLabelPurchased"
        element={<Resource name="orders/status/shippingLabelPurchased" list={OrderList} />}
      />
      <Route path="orders/status/shipped" element={<Resource name="orders/status/shipped" list={OrderList} />} />
      <Route path="orders/status/duplicate" element={<Resource name="orders/status/duplicate" list={OrderList} />} />
      <Route path="orders/status/canceled" element={<Resource name="orders/status/canceled" list={OrderList} />} />

      <Route path="kits/status/new" element={<Resource name="kits/status/new" list={KitList} />} />
      <Route
        path="kits/status/inboundShippingLabelPurchased"
        element={<Resource name="kits/status/inboundShippingLabelPurchased" list={KitList} />}
      />
      <Route path="kits/status/unregisteredPrepaid" element={<Resource name="kits/status/unregisteredPrepaid" list={KitList} />} />
      <Route path="kits/status/outToPatient" element={<Resource name="kits/status/outToPatient" list={KitList} />} />
      <Route path="kits/status/shippedByPatient" element={<Resource name="kits/status/shippedByPatient" list={KitList} />} />
      <Route path="kits/status/deliveredToLab" element={<Resource name="kits/status/deliveredToLab" list={KitList} />} />
      <Route path="kits/status/pendingConfirmation" element={<Resource name="kits/status/pendingConfirmation" list={KitList} />} />
      <Route path="kits/status/testComplete" element={<Resource name="kits/status/testComplete" list={KitList} />} />
      <Route path="kits/status/notifyComplete" element={<Resource name="kits/status/notifyComplete" list={KitList} />} />
      <Route path="kits/status/error" element={<Resource name="kits/status/error" list={KitList} />} />
      <Route path="kits/status/manualReview" element={<Resource name="kits/status/manualReview" list={KitList} />} />

      <Route path="kits" element={<Resource name="kits" list={KitList} />} />

      <Route path="providers" element={<Resource name="providers" list={ProviderList} />} />
      <Route path="notifications" element={<Resource name="notifications" list={NotificationList} />} />
      <Route path="requisitions/outToPatient" element={<Resource name="kits/status/outToPatient" list={RequisitionsDueList} />} />
      <Route
        path="requisitions/shippedByPatient"
        element={<Resource name="kits/status/shippedByPatient" list={RequisitionsDueList} />}
      />
      <Route
        path="requisitions/deliveredToLab"
        element={<Resource name="kits/status/deliveredToLab" list={RequisitionsDueList} />}
      />
      <Route path="requisitions/testComplete" element={<Resource name="kits/status/testComplete" list={RequisitionsDueList} />} />
      <Route
        path="requisitions/notifyComplete"
        element={<Resource name="kits/status/notifyComplete" list={RequisitionsDueList} />}
      />
      <Route path="requisitions/error" element={<Resource name="kits/status/error" list={RequisitionsDueList} />} />
      {/* <Route path="provider-access" element={<Resource name="provideraccess/users" list={ProviderAccessList} />} /> */}
    </CustomRoutes>

    <Resource name="kits" list={KitList} show={KitDetails} edit={KitEdit} />
    <Resource name="orders" list={OrderList} edit={OrderEdit} show={OrderDetails} />
    <Resource name="providers" list={ProviderList} show={ProviderDetails} edit={ProviderEdit} create={ProviderCreate} />
    <Resource name="notifications" list={NotificationList} show={NotificationDetails} />
    <Resource name="kits/" list={RequisitionsDueList} />
    <Resource name="provider-access" list={ProviderAccessList} show={ProviderAccessDetail} edit={ProviderAccessEdit} />
  </Admin>
)

export default App
