import {
  DateInput,
  Edit,
  FunctionField,
  NumberInput,
  SaveButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  Toolbar,
} from 'react-admin'
import { OutBoundShipmentTracking, ShopifyRawData } from '../details/helpers/orderDetailHelpers'
import moment from 'moment'
const OrderEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" />
  </Toolbar>
)

export const OrderEdit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm className="form-layout" toolbar={<OrderEditToolbar />}>
      <TextInput label="Order #" source="shopifyOrderNumber" />
      <TextInput label="Outbound Shipment Tracking Number" source="shippingTrackingNumber" />
      <TextInput multiline label="Order Notes" source="notes" />
      <SimpleShowLayout style={{ paddingLeft: 0 }} spacing={2} className="details-page-layout">
        <TextField fontSize={'medium'} label="MFH Status" source="status" />
        <FunctionField
          fontSize={'medium'}
          label="Outbound Shipping Needed?"
          render={(record) => (record.noOutboundShipping ? 'No' : 'Yes')}
        />
        <FunctionField
          label="Outbound Shipping Label"
          fontSize={'medium'}
          render={(record) =>
            record.shippingLabelUrl && (
              <a href={record.shippingLabelUrl} target="_blank" rel="noreferrer">
                <span className="fa-solid fa-file-pdf" aria-hidden="true"></span>&nbsp;PDF
              </a>
            )
          }
        />
        <FunctionField
          fontSize={'medium'}
          label="Outbound Ship Date"
          render={(record) => moment(record.shippingDate).local().format('LLLL')}
        />
        <OutBoundShipmentTracking fontSize={'medium'} label={'Outbound Shipment Tracking'} />
        <ShopifyRawData />
      </SimpleShowLayout>
    </SimpleForm>
  </Edit>
)
