export const handlePermissions = async () => {
  let permissions = {
    audio: false,
    video: false,
  }
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true,
    })

    for (const track of stream.getTracks()) {
      track.stop()
    }

    permissions = { video: true, audio: true }
  } catch (err) {
    permissions = { video: false, audio: false }
    alert('No permissions')
    console.error(err.message)
  }
  // If we still don't have permissions after requesting them display the error message
  if (!permissions.video) {
    console.error('Failed to get video permissions.')
  } else if (!permissions.audio) {
    console.error('Failed to get audio permissions.')
  }
}

export const getAvailableDevices = async () => {
  try {
    const devices = await navigator.mediaDevices.enumerateDevices()
    const videoDevices = devices.filter((d) => d.kind === 'videoinput')
    return videoDevices
  } catch (error) {
    console.log(error)
  }
}

export const getStreamFromDevice = async (videoDeviceId) => {
  try {
    const cameraStream = await navigator.mediaDevices.getUserMedia({
      video: {
        deviceId: videoDeviceId,
      },
    })

    return cameraStream
  } catch (error) {
    console.log(error)
  }
}

export const dataURLtoBlob = async (dataURL) => {
  const response = await fetch(dataURL);
  const blob = await response.blob();
  return blob;
};