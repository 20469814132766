import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useNotify, useRefresh } from 'react-admin'
import { getAvailableDevices, getStreamFromDevice, handlePermissions } from '../../utils/media'
import { recordTestResult } from '../../utils/progressKit'
import { fetchResultImages } from '../details/helpers/kitDetailsHelpers'
import { client } from '../../providers/data/client'
import ImagePreview from '../common/ImagePreview'
import moment from 'moment'
import watermark from 'watermarkjs'

const adviceCodeDetails = {
  RARE: [
    {
      code: 'acRetest4Week',
      spec: '',
      msg: 'RARE sperm seen in sample. Recommend to retest after at least another 4 weeks and 10 ejaculations. Clearance of sperm after vasectomy can take multiple months.',
    },
  ],
  MODERATE: [
    {
      code: 'acRetest6Week',
      spec: '(MODERATE and 8+ weeks since DoV)',
      msg: 'MODERATE sperm seen in sample. Recommend to retest after at least another 6 weeks and 15 ejaculations. Clearance of sperm after vasectomy can take multiple months.',
    },
    {
      code: 'acRetest8Week',
      spec: '(MODERATE, or HIGH and < 8 weeks since DoV)',
      msg: 'MODERATE/HIGH sperm seen in sample. Recommend to retest after at least another 8 weeks and 20 ejaculations. Clearance of sperm after vasectomy can take multiple months.',
    },
  ],
  HIGH: [
    {
      code: 'acRetest8Week',
      spec: '(MODERATE, or HIGH and < 8 weeks since DoV)',
      msg: 'MODERATE/HIGH sperm seen in sample. Recommend to retest after at least another 8 weeks and 20 ejaculations. Clearance of sperm after vasectomy can take multiple months.',
    },
    {
      code: 'acConsultProvider',
      spec: '(HIGH and 8+ weeks since DOV)',
      msg: 'MODERATE/HIGH sperm seen in sample. Please contact your physician to discuss this result.',
    },
  ],
}

const modifyCollectionDateForEdit = (collectionDate) => {
  const [month, day, year] = collectionDate.split('/')
  return `${year}-${month}-${day}`
}

const TestRecordModal = ({ modalState, open, handleClose, edit = false }) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const navigate = useNavigate()
  const [resultRecord, setResultRecord] = useState({
    collectionDate: edit && modalState.specimenCollectionDate ? modifyCollectionDateForEdit(modalState.specimenCollectionDate) : '',
    result: edit && modalState.labTestResult ? modalState.labTestResult : '',
    sampleGrade: edit && modalState.labTestSampleGrade ? modalState.labTestSampleGrade : '',
    note: edit && modalState.notes ? modalState.notes : '',
    providerNotes: edit && modalState.labTestProviderNotes ? modalState.labTestProviderNotes : '',
  })

  const [errorState, setErrorState] = useState({
    collectionDate: null,
    result: null,
    sampleGrade: null,
    note: null,
    providerNotes: null,
  })

  const [camerasList, setCamerasList] = useState([])
  const [selectedCamera, setSelectedCamera] = useState('')
  const [capturedImages, setCapturedImages] = useState([])
  const [existingImages, setExistingImages] = useState([])
  const [patientIdCard, setPatientIdCard] = useState([])
  const [imagePreviewImagePath, setImagePreviewImagePath] = useState('')
  const [imagePreviewState, setImagePreviewState] = useState(false)

  const handleImagePreview = (bool) => {
    setImagePreviewState(bool)
  }

  useEffect(() => {
    if (edit) {
      ;(async () => {
        const images = await fetchResultImages(modalState.kitId)
        setExistingImages(images)
      })()
    }
  }, [edit, modalState])

  useEffect(() => {
    existingImages.forEach((image) => {
      if (image.isPatientIdCard) setPatientIdCard((prev) => [...prev, image.url])
    })
  }, [existingImages])

  useEffect(() => {
    ;(async () => {
      await handlePermissions()

      const lastDeviceId = localStorage.getItem('selectedDeviceId')
      if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
        const devices = await getAvailableDevices()
        setCamerasList(devices)
        // Set to last deviceId, if any
        if (lastDeviceId) {
          setSelectedCamera(lastDeviceId)
          handleCameraSelect(lastDeviceId)
        } else {
          setSelectedCamera(devices[0].deviceId)
          handleCameraSelect(devices[0].deviceId)
        }
      } else {
        notify("Browser doesn't support cameras!", { type: 'error' })
      }
    })()

    return () => {
      clearStates()
    }
  }, [open])

  const clearStates = () => {
    setCapturedImages([])
    setResultRecord({
      collectionDate: '',
      result: '',
      sampleGrade: '',
      note: '',
      providerNotes: '',
    })
    setCamerasList([])
    clearCamera()
  }

  const clearCamera = () => {
    const videoElement = document.getElementById('camera-video')
    setSelectedCamera('')
    if (videoElement && videoElement.srcObject) {
      const tracks = videoElement.srcObject.getTracks()
      tracks.forEach((track) => track.stop())
      videoElement.srcObject = null
    }
  }

  const handleCameraSelect = async (deviceId) => {
    try {
      localStorage.setItem('selectedDeviceId', deviceId)
      const videoElement = document.getElementById('camera-video')
      setSelectedCamera(deviceId)

      const stream = await getStreamFromDevice(deviceId)
      if (videoElement) {
        videoElement.srcObject = stream
        videoElement.play()
      }
    } catch (error) {
      console.error('Error accessing camera:', error)
    }
  }

  const valid = () => {
    let result = true
    const errorObj = {
      collectionDate: null,
      result: null,
      sampleGrade: null,
      note: null,
      providerNotes: null,
    }

    /*
    if (resultRecord.collectionDate === '') {
      errorObj.collectionDate = 'Collection Date is required'
      result = false
    }
    */

    if (resultRecord.result === 'error' && resultRecord.note === '') {
      errorObj.note = "Note is required when test result is 'error'"
      result = false
    }

    if (resultRecord.result === 'Positive' && resultRecord.providerNotes === '') {
      errorObj.providerNotes = "Please enter 'Provider Notes' for a 'Positive' test result"
      result = false
    }

    if (resultRecord.result === '') {
      errorObj.result = 'Result is required'
      result = false
    }
    if (resultRecord.sampleGrade === '') {
      errorObj.sampleGrade = 'Sample Grade is required'
      result = false
    }
    setErrorState(errorObj)
    return result
  }

  const handleSave = async () => {
    try {
      if (!valid()) {
        return
      }
      clearCamera()
      await recordTestResult({ ...resultRecord, images: capturedImages }, patientIdCard, modalState, notify, refresh, navigate)
      handleClose()
    } catch (error) {
      console.log(error)
    }
  }

  const handleDelete = async (image) => {
    if (patientIdCard.includes(image)) {
      setPatientIdCard(patientIdCard.filter((i) => i !== image))
    }

    if (image.includes('http') && edit) {
      const tempList = [...existingImages]
      const deleteableImage = tempList.splice(
        existingImages.findIndex((i) => i.url === image),
        1
      )
      const splitedKey = deleteableImage[0].key.split('/')
      const response = await client().delete(`kits/${modalState?.kitId}/artifact/${splitedKey[splitedKey.length - 1]}`)
      if (response.data.success) setExistingImages(tempList)
      return
    }

    const tempList = [...capturedImages]
    tempList.splice(capturedImages.indexOf(image), 1)
    setCapturedImages(tempList)
    return
  }

  const handleInput = (target) => {
    const { name, value } = target

    if (name === 'result') {
      switch (value) {
        case 'NSS':
          setResultRecord({
            ...resultRecord,
            [name]: value,
            sampleGrade: 'NONE',
          })
          break
        case 'Positive':
          setResultRecord({
            ...resultRecord,
            [name]: value,
            sampleGrade: 'HIGH',
          })
          break
        case 'error':
          setResultRecord({
            ...resultRecord,
            [name]: value,
            sampleGrade: 'NONE',
          })
          break
        default:
          break
      }
      return
    }

    setResultRecord({
      ...resultRecord,
      [name]: value,
    })
  }

  const calculateWeeksSinceVasectomy = (fromDate, toDate) => {
    var formats = ['MM-DD-YYYY', 'YYYY-MM-DD', 'MM.DD.YY', 'M.DD.YY', 'MM/DD/YY', 'MM/DD/YYYY', 'YYYYMMDD']

    if (!isNaN(fromDate)) {
      fromDate = moment.unix(fromDate).format('MM/DD/YYYY')
    }
    if (!isNaN(toDate)) {
      toDate = moment.unix(toDate).format('MM/DD/YYYY')
    }

    if (!fromDate || !toDate) {
      return 0
    } else {
      return (moment(toDate, formats).diff(moment(fromDate, formats), 'days') / 7).toFixed(1)
    }
  }

  return (
    <>
      <Modal
        className="modal"
        open={open}
        onClose={() => {
          clearStates()
          handleClose()
        }}
      >
        <Box className="test-modal-container">
          <h4>KitId {modalState?.kitId} - Record Test</h4>
          <hr style={{ margin: '8px 0' }} />
          {modalState && (
            <form className="test-record-form">
              <div>
                <Grid container columns={7} spacing={1}>
                  <Grid item xs={2}>
                    <div>
                      <p>{modalState?.patientName}</p>
                      <p>DoB: {modalState?.patientDoB}</p>
                      <p>DoV: {modalState?.patientDoV}</p>
                    </div>
                    <div>
                      <FormControl sx={{ width: '200px' }}>
                        <FormLabel>Collection Date</FormLabel>
                        <TextField
                          name="collectionDate"
                          onChange={(event) => handleInput(event.target)}
                          value={resultRecord.collectionDate}
                          type="date"
                          variant="filled"
                          helperText={errorState.collectionDate}
                          error={errorState.collectionDate ? true : false}
                        />
                      </FormControl>
                    </div>
                    <div>
                      {resultRecord.collectionDate !== '' && (
                        <span className="weeksSinceVas">
                          <b>{calculateWeeksSinceVasectomy(modalState?.patientDoV, resultRecord.collectionDate)}</b> weeks since
                          vasectomy
                        </span>
                      )}
                    </div>
                    <div>
                      <FormControl>
                        <FormLabel>Test Result</FormLabel>
                        <RadioGroup
                          name="result"
                          onChange={(event) => handleInput(event.target)}
                          className="radio-group"
                          value={resultRecord.result}
                        >
                          <FormControlLabel value="NSS" control={<Radio />} label={<p class="btn-sm bg-success">NSS</p>} />
                          <FormControlLabel value="Positive" control={<Radio />} label={<p class="btn-sm bg-danger">Positive</p>} />
                          <FormControlLabel value="error" control={<Radio />} label={<p class="btn-sm bg-warning">Error</p>} />
                        </RadioGroup>
                        <FormHelperText error={errorState.result ? true : false}>{errorState.result}</FormHelperText>
                      </FormControl>
                    </div>
                    <div>
                      <FormControl>
                        <FormLabel>Test Sample Grade</FormLabel>
                        <Select
                          label="Sample Grade"
                          name="sampleGrade"
                          onChange={(event) => handleInput(event.target)}
                          value={resultRecord.sampleGrade}
                        >
                          <MenuItem value="NONE">NONE (0/hpf)</MenuItem>
                          <MenuItem value="RARE">RARE (&nbsp;1/hpf)</MenuItem>
                          <MenuItem value="MODERATE">MODERATE (~1-10/hpf)</MenuItem>
                          <MenuItem value="HIGH">HIGH (10+/hpf)</MenuItem>
                        </Select>

                        <FormHelperText error={errorState.sampleGrade ? true : false}>{errorState.sampleGrade}</FormHelperText>
                      </FormControl>
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={3}
                    direction="column"
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                    }}
                  >
                    <div className="video-container">
                      <video id="camera-video" autoPlay width="360px" height="240px"></video>
                    </div>

                    <div>
                      <FormControl sx={{ width: '160px' }}>
                        <InputLabel>Camera</InputLabel>
                        <Select value={selectedCamera} label="Camera" onChange={(event) => handleCameraSelect(event.target.value)}>
                          {camerasList.map((camera, index) => {
                            return (
                              <MenuItem key={index} value={camera.deviceId} selected={index === 0}>
                                {camera.label || `Camera ${camerasList.indexOf(camera) + 1}`}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>

                      <Button
                        onClick={async () => {
                          const video = document.getElementById('camera-video')
                          const canvas = document.getElementById('img-canvas')
                          const ctx = canvas.getContext('2d')
                          canvas.width = 720
                          canvas.height = 480
                          ctx.drawImage(video, 0, 0, canvas.width, canvas.height)

                          // White transparent box on which we render the watermark text
                          ctx.fillStyle = 'rgba(255, 255, 255, 0.5)'
                          ctx.fillRect(0, canvas.height - 30, 400, 30)

                          const tempList = [...capturedImages]
                          const imageDataUrl = canvas.toDataURL('image/png')
                          const imgWithWatermark = await watermark([imageDataUrl]).image(
                            watermark.text.lowerLeft(
                              `MFH Lab Services  KitID ${modalState?.kitId}  ${moment().format('MM/DD/YYYY')}`,
                              '14px courier new',
                              '#000',
                              0.8
                            )
                          )

                          tempList.push(imgWithWatermark.src)
                          setCapturedImages(tempList)
                        }}
                        variant="contained"
                        disabled={selectedCamera ? false : true}
                        sx={{ marginLeft: 8, width: 'max-content' }}
                      >
                        Capture
                      </Button>
                    </div>
                  </Grid>

                  <Grid item xs={2}>
                    <div>
                      <FormControl fullWidth>
                        <FormLabel>Notes (MFH Only)</FormLabel>
                        <TextField
                          multiline
                          rows={3}
                          name="note"
                          onChange={(event) => handleInput(event.target)}
                          value={resultRecord.note}
                          type="text"
                          variant="filled"
                          error={errorState.note ? true : false}
                          helperText={errorState.note}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <FormLabel>Provider Notes</FormLabel>
                        <TextField
                          multiline
                          rows={3}
                          name="providerNotes"
                          onChange={(event) => handleInput(event.target)}
                          value={resultRecord.providerNotes}
                          type="text"
                          variant="filled"
                          error={errorState.providerNotes ? true : false}
                          helperText={errorState.providerNotes}
                        />
                      </FormControl>
                    </div>
                  </Grid>

                  <Grid item xs={7}>
                    {resultRecord.sampleGrade !== '' && (
                      <div
                        style={{ backgroundColor: '#efefef', padding: '2px', display: 'flex', flexFlow: 'column', rowGap: '8px' }}
                      >
                        {adviceCodeDetails[resultRecord.sampleGrade]?.map((item, index) => {
                          return (
                            <p>
                              <b>{item.code}</b> <i>{item.spec}</i>
                              <br />
                              {item.msg}
                            </p>
                          )
                        })}
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={7}>
                    <Box
                      sx={{
                        display: 'flex',
                        columnGap: '8px',
                        width: '100%',
                        justifyContent: 'end',
                      }}
                    >
                      <Typography variant="body2" sx={{ alignSelf: 'center', fontWeight: 'bold', color: '#7d3c98' }}>
                        {modalState?.patientPictures ? (
                          <>
                          <span style={{ color: '#7d3c98' }}>
                            <i className="fa-solid fa-camera" title='Patient has access to lab result pictures.'></i>
                          </span>
                          <span style={{ paddingLeft: '4px', paddingRight: '8px'}}>
                            Patient Pictures Enabled
                          </span>                          
                          </>

                        ) : null}
                      </Typography>

                      <Typography variant="body2" sx={{ alignSelf: 'center', fontWeight: 'bold', color: 'var(--danger-color)' }}>
                        Please capture at least 4 images
                      </Typography>

                      <Button
                        size="small"
                        type="submit"
                        onClick={async (event) => {
                          event.preventDefault()
                          await handleSave()
                        }}
                        variant="contained"
                        sx={{ width: 'max-content' }}
                      >
                        Save
                      </Button>
                      <Button
                        size="small"
                        onClick={() => {
                          clearStates()
                          handleClose()
                        }}
                        variant="contained"
                        sx={{ width: 'max-content' }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </form>
          )}
          <hr style={{ margin: '8px 0' }} />
          <div className="img-capture-section">
            <form className="image-container">
              {existingImages
                .map((i) => i.url)
                .concat(capturedImages)
                .map((item, index) => {
                  return (
                    <div>
                      <div className="img-thumb-actions">
                        <div>
                          <input
                            type="checkbox"
                            id={`test-img-${index}`}
                            name="patientIdCard"
                            value={item}
                            checked={patientIdCard.includes(item)}
                            onChange={(e) =>
                              setPatientIdCard(
                                e.target.checked
                                  ? [...patientIdCard, e.target.value]
                                  : patientIdCard.filter((i) => i !== e.target.value)
                              )
                            }
                          />
                          <label htmlFor={`test-img-${index}`}>Patient ID Card</label>
                        </div>
                        <span onClick={async () => await handleDelete(item)} className="fa-solid fa-trash"></span>
                      </div>
                      <img
                        className={'img-thumb' + (patientIdCard.includes(item) ? '-patientidcard' : '')}
                        key={index}
                        src={item}
                        onClick={() => {
                          setImagePreviewImagePath(item)
                          handleImagePreview(true)
                        }}
                        alt="result"
                      />
                    </div>
                  )
                })}
              <canvas style={{ display: 'none' }} id="img-canvas" width={100} height={100}></canvas>
            </form>
          </div>
          <ImagePreview imagePath={imagePreviewImagePath} open={imagePreviewState} setOpen={handleImagePreview} />
        </Box>
      </Modal>
    </>
  )
}

export default TestRecordModal
