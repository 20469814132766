import { client } from '../providers/data/client'
import { api2pdfclient } from '../providers/data/api2pdfclient'

export const assignKits = async (orders, notify) => {
  notify(`Assigning Kits to Orders ...`, {
    type: 'info',
    undoable: false,
    multiLine: true,
  })

  const orderIds = orders.map((order) => order.orderId)

  try {
    const response = await client().post(`/orders/assignKits`, { orderIds: orderIds })

    notify(`Kits Assigned to Orders Successfully ....`, {
      type: 'success',
      undoable: false,
      multiLine: true,
    })
  } catch (error) {
    // Check if the error has a response object
    if (error.response) {
      // Log the response data
      console.log('Response data:', error.response.data)

      // Notify with a more detailed error message
      notify(`Error: ${error.response.data.errorMessage || 'An error occurred'}`, {
        type: 'error',
        undoable: false,
        multiLine: true,
      })
    } else {
      // If there's no response object, log the entire error
      console.log('Error:', error)

      // Notify with a generic error message
      notify('An unexpected error occurred', {
        type: 'error',
        undoable: false,
        multiLine: true,
      })
    }
  }
}

export const createAllShippingLabels = async (orders, notify) => {
  notify(`Creating inbound and outbound labels for Orders`, {
    type: 'info',
    undoable: false,
    multiLine: true,
  })

  const orderIds = orders.map((order) => order.orderId)

  try {
    const response = await client().post(`/orders/createAllShippingLabels`, {
      orderIds,
    })

    notify(
      `${response.data.inboundLabelsCreated} inbound shipping label(s) are ready to print\n${response.data.outboundLabelsCreated} outbound shipping label(s) are ready to print`,
      {
        multiLine: true,
        type: 'success',
        undoable: false,
      }
    )

    if (response.data.instructions.length) {
      response.data.instructions.map((instruction) =>
        notify(`${instruction} [Close]`, {
          multiLine: true,
          type: 'success',
          undoable: false,
        })
      )
    }
  } catch (error) {
    console.log(error)
    notify('A problem occurred, please try again', {
      type: 'error',
      undoable: false,
      multiLine: true,
    })
  }
}

export const printAllShippingLabels = async (orders, notify) => {
  notify(
    `Getting reg labels and inbound/outbound shipping labels for ${orders.length > 1 ? orders.length : ''} Orders ${
      orders.length === 1 ? '#' + orders[0].shopifyOrderNumber : ''
    } ...`,
    {
      multiLine: true,
      type: 'info',
      undoable: false,
    }
  )

  const orderIds = orders.map((order) => order.orderId)

  try {
    const response = await client().post(`/orders/mergeAllShippingLabels`, { orderIds })

    const labelCount = response.data.inputPdfs.length
    const key = response.data.key

    if (labelCount > 10) {
      // Warn for "large" merges ...
      notify(`Merging ${labelCount} PDFs ...\nPLEASE WAIT! Do not refresh or close browser ... this may take up to 60 seconds!`, {
        type: 'info',
        undoable: false,
        multiLine: true,
      })
    }

    // Call Api2Pdf to merge these ...
    const endpoint = 'https://v2.api2pdf.com/pdfsharp/merge'
    const payload = {
      urls: response.data.inputPdfs,
      fileName: 'orders-mergedlabels.pdf',
      inline: false,
    }

    const result = await api2pdfclient().post(endpoint, payload, {
      headers: { Authorization: key },
    })

    // Open a window with a unique name based on current timestamp. Otherwise, browser
    // may not be able to find/use/open the previously opened window with the same title.
    let title = 'orders-mergedlabels-' + Date.now() + '.pdf'
    window.open(result.data.FileUrl, /* window title */ title)
  } catch (error) {
    console.log(error)
    notify('A problem occurred, please try again', {
      type: 'error',
      multiLine: true,
      undoable: false,
    })
  }
}

export const markAsShipped = async (orders, notify, promptForTrackingEntryAtShopify = false, refresh) => {
  if (promptForTrackingEntryAtShopify) {
    // eslint-disable-next-line no-restricted-globals
    if (
      // eslint-disable-next-line no-restricted-globals
      !confirm(
        'The order(s) will be marked as "Fulfilled" in Shopify.\n\nIf you have manually shipped a package for this order,\nyou can also go to Shopify to enter a tracking number\nand notify the customer.\n\nProceed with marking order(s) as shipped?'
      )
    ) {
      // Canceled
      return false
    }
  }

  let messages = []

  let notifyMsg = `Marking ${orders.length > 1 ? orders.length : ''} Order ${
    orders.length === 1 ? '#' + orders[0].shopifyOrderNumber : ''
  } as Shipped/fulfilled.`
  // if (order.kitIds && order.kitIds.length) {
  //   notifyMsg += `Marking ${order.kitIds.length} Kit(s) as "Out To Patient" ...`
  // }
  // if (order.prepaidKitIds && order.prepaidKitIds.length) {
  //   notifyMsg += `Marking ${order.prepaidKitIds.length} Kit(s) as "Unregistered Prepaid" ...`
  // }

  notify(notifyMsg, {
    type: 'info',
    multiLine: true,
    undoable: false,
  })

  const orderIds = orders.map((order) => order.orderId)

  try {
    const response = await client().post(`/orders/markAsShipped`, { orderIds })
    messages = response.data.messages

    refresh()
    notify(messages.join('\n'), {
      type: 'success',
      undoable: false,
      multiLine: true,
    })
  } catch (error) {
    console.log(error)
    notify('A problem occurred, please retry', {
      type: 'error',
      undoable: false,
      multiLine: true,
    })
  }
}
