import { useState } from 'react'
import Box from '@mui/material/Box'
import SubMenu from './SubMenu'
import { MenuItemLink, useSidebarState } from 'react-admin'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import StarIcon from '@mui/icons-material/Star'
import ListIcon from '@mui/icons-material/List'
import InventoryIcon from '@mui/icons-material/Inventory'
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import HomeIcon from '@mui/icons-material/Home'
import PestControlIcon from '@mui/icons-material/PestControl'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import MailIcon from '@mui/icons-material/Mail'
import HelpIcon from '@mui/icons-material/Help'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import ScienceIcon from '@mui/icons-material/Science'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import ErrorIcon from '@mui/icons-material/Error'
import VisibilityIcon from '@mui/icons-material/Visibility'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { Divider } from '@mui/material'

const Menu = () => {
  const [state, setState] = useState({
    menuOrders: false,
    menuKits: true,
    menuProviders: false,
    menuNotifications: false,
    menuRequisitions: false,
    providerAccess: false,
  })

  const [open] = useSidebarState()

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }))
  }

  return (
    <Box
      sx={{
        width: open ? '100%' : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      <SubMenu handleToggle={() => handleToggle('menuOrders')} isOpen={state.menuOrders} name="Orders" icon={<ShoppingCartIcon />}>
        <MenuItemLink to="/orders" state={{ _scrollToTop: true }} primaryText={`All`} leftIcon={<ListIcon />} />
        <MenuItemLink to="/orders/status/new" state={{ _scrollToTop: true }} primaryText={`New`} leftIcon={<StarIcon />} />
        <MenuItemLink
          to="/orders/status/kitsAssignedReadyToShip"
          state={{ _scrollToTop: true }}
          primaryText={`Ready to Ship`}
          leftIcon={<InventoryIcon />}
        />
        <MenuItemLink
          to="/orders/status/shippingLabelPurchased"
          state={{ _scrollToTop: true }}
          primaryText={`Shipping Label Purchased`}
          leftIcon={<LocalOfferIcon />}
        />
        <MenuItemLink to="/orders/status/shipped" state={{ _scrollToTop: true }} primaryText={`Shipped`} leftIcon={<HomeIcon />} />
        <MenuItemLink
          to="/orders/status/duplicate"
          state={{ _scrollToTop: true }}
          primaryText={`Duplicates`}
          leftIcon={<PestControlIcon />}
        />
        <MenuItemLink
          to="/orders/status/canceled"
          state={{ _scrollToTop: true }}
          primaryText={`Canceled`}
          leftIcon={<DoNotDisturbIcon />}
        />
      </SubMenu>
      <Divider />
      <SubMenu handleToggle={() => handleToggle('menuKits')} isOpen={state.menuKits} name="Kits" icon={<InventoryIcon />}>
        <MenuItemLink to="/kits" state={{ _scrollToTop: true }} primaryText={`All`} leftIcon={<ListIcon />} />
        <MenuItemLink to="/kits/status/new" state={{ _scrollToTop: true }} primaryText={`New`} leftIcon={<StarIcon />} />
        <MenuItemLink
          to="/kits/status/inboundShippingLabelPurchased"
          state={{ _scrollToTop: true }}
          primaryText={`Ready to Ship`}
          leftIcon={<MailIcon />}
        />
        <MenuItemLink
          to="/kits/status/unregisteredPrepaid"
          state={{ _scrollToTop: true }}
          primaryText={`Unregistered Prepaid`}
          leftIcon={<HelpIcon />}
        />
        <MenuItemLink
          to="/kits/status/outToPatient"
          state={{ _scrollToTop: true }}
          primaryText={`Out to Patient`}
          leftIcon={<HomeIcon />}
        />
        <MenuItemLink
          to="/kits/status/shippedByPatient"
          state={{ _scrollToTop: true }}
          primaryText={`Shipped by Patient`}
          leftIcon={<LocalShippingIcon />}
        />
        <MenuItemLink
          to="/kits/status/deliveredToLab"
          state={{ _scrollToTop: true }}
          primaryText={`Delivered to Lab`}
          leftIcon={<ScienceIcon />}
        />
        <MenuItemLink
          to="/kits/status/pendingConfirmation"
          state={{ _scrollToTop: true }}
          primaryText={`Pending Confirmation`}
          leftIcon={<PendingActionsIcon />}
        />
        <MenuItemLink
          to="/kits/status/testComplete"
          state={{ _scrollToTop: true }}
          primaryText={`Test Complete`}
          leftIcon={<CheckCircleIcon />}
        />
        <MenuItemLink
          to="/kits/status/notifyComplete"
          state={{ _scrollToTop: true }}
          primaryText={`Notify Complete`}
          leftIcon={<AlternateEmailIcon />}
        />
        <MenuItemLink to="/kits/status/error" state={{ _scrollToTop: true }} primaryText={`Error`} leftIcon={<ErrorIcon />} />
        <MenuItemLink
          to="/kits/status/manualReview"
          state={{ _scrollToTop: true }}
          primaryText={`Manual Review`}
          leftIcon={<VisibilityIcon />}
        />
      </SubMenu>
      <Divider />
      <SubMenu
        handleToggle={() => handleToggle('menuProviders')}
        isOpen={state.menuProviders}
        name="Providers"
        icon={<AccountCircleIcon />}
      >
        <MenuItemLink to="/providers" state={{ _scrollToTop: true }} primaryText={'All'} leftIcon={<AccountCircleIcon />} />
      </SubMenu>
      <Divider />
      <SubMenu
        handleToggle={() => handleToggle('menuNotifications')}
        isOpen={state.menuNotifications}
        name="Notifications"
        icon={<NotificationsIcon />}
      >
        <MenuItemLink
          to="/notifications"
          state={{ _scrollToTop: true }}
          primaryText={'Provider'}
          leftIcon={<AccountCircleIcon />}
        />
      </SubMenu>
      <Divider />
      <SubMenu
        handleToggle={() => handleToggle('menuRequisitions')}
        isOpen={state.menuRequisitions}
        name="Requisitions Due"
        icon={<NotificationsIcon />}
      >
        <MenuItemLink
          to="/requisitions/outToPatient"
          state={{ _scrollToTop: true }}
          primaryText={'Out To Patient'}
          leftIcon={<AccountCircleIcon />}
        />
        <MenuItemLink
          to="/requisitions/shippedByPatient"
          state={{ _scrollToTop: true }}
          primaryText={'Shipped By Patient'}
          leftIcon={<AccountCircleIcon />}
        />
        <MenuItemLink
          to="/requisitions/deliveredToLab"
          state={{ _scrollToTop: true }}
          primaryText={'Delivered To Lab'}
          leftIcon={<AccountCircleIcon />}
        />
        <MenuItemLink
          to="/requisitions/testComplete"
          state={{ _scrollToTop: true }}
          primaryText={'Test Complete'}
          leftIcon={<AccountCircleIcon />}
        />
        <MenuItemLink
          to="/requisitions/notifyComplete"
          state={{ _scrollToTop: true }}
          primaryText={'Notify Complete'}
          leftIcon={<AccountCircleIcon />}
        />
        <MenuItemLink
          to="/requisitions/error"
          state={{ _scrollToTop: true }}
          primaryText={'Error'}
          leftIcon={<AccountCircleIcon />}
        />
      </SubMenu>
      <Divider />
      <SubMenu
        handleToggle={() => handleToggle('providerAccess')}
        isOpen={state.providerAccess}
        name="Provider Access"
        // icon={<NotificationsIcon />}
      >
        <MenuItemLink
          to="/provider-access"
          state={{ _scrollToTop: true }}
          primaryText={'Provider Access'}
          leftIcon={<AdminPanelSettingsIcon />}
        />
      </SubMenu>
    </Box>
  )
}

export default Menu
