import { JSONToHTMLTable } from '@kevincobain2000/json-to-html-table'
import * as React from 'react'
import { Show, SimpleShowLayout, TextField, FunctionField,
  ReferenceField, TabbedShowLayout
  } from 'react-admin'
  import {
    Box,
    Grid,
  } from '@mui/material'
  import {
  fromTimestamp,
  labTestAdviceCodeChoices,
  labTestResultChoices,
  labTestResultLabel,
  labTestSampleGradeChoices,
} from '../fields/helpers/kitFieldsHelpers'
import { PatientField, ShipField } from '../fields/kitFields'
import { OrderCustomerDetailField, OrderItemsField } from '../fields/orderFields'
import { AdviceCodeDetailTable, fetchResultImages, formatResultRetrieval, Title } from './helpers/kitDetailsHelpers'
import ImagePreview from '../common/ImagePreview'
import Carousel from '../common/Carousel'

export default function KitDetails() {
  const [resultImages, setResultImages] = React.useState([])
  const [imagePreviewImagePath, setImagePreviewImagePath] = React.useState('')
  const [imagePreviewState, setImagePreviewState] = React.useState(false)

  const handleImagePreview = (bool) => {
    setImagePreviewState(bool)
  }

  return (
    <Show title={<Title />}>

      <Box sx={{ flexGrow: 1, border: '2px solid black', padding: '16px' }}>
        <Grid container spacing={1}>
          <Grid item xs={1}>
            <SimpleShowLayout spacing={1} className="details-page-layout">
              <TextField label="Status" source="status" />
            </SimpleShowLayout>
          </Grid>
          <Grid item xs={1}>
          <SimpleShowLayout spacing={1} className="details-page-layout">
              <TextField label="Order #" source="shopifyOrderNumber" />
            </SimpleShowLayout>
          </Grid>
          <Grid item xs={3}>
            <SimpleShowLayout spacing={1} className="details-page-layout">
              <TextField label="Patient" source="patientName" />
            </SimpleShowLayout>
          </Grid>
          <Grid item xs={3}>
            <SimpleShowLayout spacing={1} className="details-page-layout">
              <ReferenceField label="Provider" source={'providerId'} reference="providers">
                <TextField source="displayName" />
              </ReferenceField>
            </SimpleShowLayout>
          </Grid>
          <Grid item xs={2}>
          <SimpleShowLayout spacing={1} className="details-page-layout">
            <FunctionField
              label="Lab Test Result"
              render={(record) => {
                return labTestResultLabel(record)
              }}
            />
            </SimpleShowLayout>
          </Grid>
          <Grid item xs={2}>
          <SimpleShowLayout spacing={1} className="details-page-layout">

              <FunctionField label="Pictures"
                render={(record) => {
                  const tooltip = 'Patient has access to lab result pictures.'
                  if (record.patientPictures) {
                    return (
                      <>
                        <span style={{ color: '#7d3c98' }}>
                          <i className="fa-solid fa-camera" title={tooltip}></i>
                          <span> {record.resultImages.flat().length}</span>
                        </span>
                      </>
                    )
                  }
                  return (
                    <>
                        <span>{record.resultImages.flat().length}</span>
                   </>
                  )
            }}
          />



            </SimpleShowLayout>
          </Grid>
        </Grid>
      </Box>



      <div style={{ padding: '1rem', backgroundColor: '#f5f5f5', marginBottom: '1rem' }}>
      </div>

      <TabbedShowLayout spacing={2}>
        <TabbedShowLayout.Tab label="Summary">
          <FunctionField
            label="Order Details"
            render={() => {
              return (
                <>
                  <ReferenceField source={'orderId'} link={false} reference="orders">
                    <OrderCustomerDetailField  />
                    <OrderItemsField label="Order Items" />
                  </ReferenceField>
                </>
              )
            }}
          />

          <FunctionField
            label="Kit Notes (MFH Only)"
            render={(record) => (
              <p style={{ whiteSpace: 'pre' }}>{record.notes ? record.notes.replace(/(<([^>]+)>)/gi, '') : record.notes}</p>
            )}
          />

        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label="Patient">
          <TextField label="Patient Name" source="patientName" />
          <TextField label="Patient DOB" source="patientDoB" />
          <TextField label="Patient Date of Vasectomy" source="patientDoV" />
          <TextField label="Patient Email" source="patientEmail" />
          <TextField label="Patient Phone" source="patientPhone" />
          <TextField label="Patient Notification Method" source="patientNotificationMethod" />
          <ReferenceField label="Provider (in MFH DB)" source={'providerId'} reference="providers">
            <TextField source="displayName" />
          </ReferenceField>
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label="Shipping">
          <FunctionField label="Inbound Shipping" render={() => <ShipField />} />
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label="Requisition">
          <FunctionField
            label="Test Requisition"
            render={(record) => {
              if (record && record.testRequisition) {
                return (
                  <div>
                    <p>
                      <i>(see below)</i>
                    </p>
                    <JSONToHTMLTable data={record.testRequisition} tableClassName="detail_table " />
                  </div>
                )
              } else {
                return <div style={{ color: 'red' }}>No test requisition on file.</div>
              }
            }}
          />
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label="Notifications">
          <FunctionField
              render={(record) => record.patientNotifiedAt && `${fromTimestamp(record.patientNotifiedAt)} `}
              label="Patient Last Notified"
            />
            <TextField source="patientNotifiedMethod" label="Patient Last Notified Method" />
            <TextField source="patientNotifiedBy" label="Patient Last Notified By" />
            <FunctionField
              render={(record) =>
                record.patientNotifications && <JSONToHTMLTable data={record.patientNotifications} tableClassName="detail_table " />
              }
              label="Patient Notifications"
            />

        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label="Retrievals">
          <FunctionField
            render={(record) => {
              return formatResultRetrieval(record)
            }}
            label="Result Retrieval Code"
          />
          <FunctionField
            label="Result Retrieval Locked?"
            render={(record) => (record.resultRetrievalLocked ? 'Locked' : 'Unlocked')}
          />
          <FunctionField
            render={(record) =>
              record.resultRetrievals && <JSONToHTMLTable data={record.resultRetrievals} tableClassName="detail_table " />
            }
            label="Result Retrievals"
          />
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label="Test Result">
          <FunctionField
              render={(record) => record.labReceivedAt && `${fromTimestamp(record.labReceivedAt)} `}
              label="Lab Received At"
          />
          <TextField label="Specimen Collection Date" source="specimenCollectionDate" />
          <TextField label="Weeks Since Vasectomy" source="weeksSinceVasectomy" />
          <FunctionField render={(record) => record.labTestedAt && `${fromTimestamp(record.labTestedAt)} `} label="Lab Tested At" />
          <TextField label="Lab Tested By" source="labTestedBy" />
          <FunctionField
            label="Lab Test Result"
            render={(record) => {
              const result = labTestResultChoices.filter((r) => r.value === record.labTestResult)
              return result.length !== 0 ? result[0].label : ''
            }}
          />
          <FunctionField
            label="Lab Test Sample Grade"
            render={(record) => {
              const result = labTestSampleGradeChoices.filter((r) => r.value === record.labTestSampleGrade)
              return result.length !== 0 ? result[0].label : ''
            }}
          />
          <FunctionField
            label="Lab Test Advice Code"
            render={(record) => {
              const result = labTestAdviceCodeChoices.filter((r) => r.value === record.labTestAdviceCode)
              return result.length !== 0 ? result[0].label : ''
            }}
          />

          <FunctionField
            label="Lab Tested Provider Notes"
            render={(record) => (
              <p style={{ whiteSpace: 'pre' }}>
                {record.labTestProviderNotes ? record.labTestProviderNotes.replace(/(<([^>]+)>)/gi, '') : record.labTestProviderNotes}
              </p>
            )}
          />

          <AdviceCodeDetailTable />

        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label="Pictures">
          <FunctionField
            label="Can Patient View Pictures?"
            render={(record) => {
              const tooltip = 'Patient has access to lab result pictures.'
              if (record.patientPictures) {
                return (
                  <>
                    <span style={{ color: '#7d3c98' }}>
                      <i className="fa-solid fa-camera" title={tooltip}></i>
                    </span>
                    <div>Yes, patient has access to lab pictures on results page.</div>
                  </>
                )
              }
              return (
                <>
                  <span>No</span>
                </>
              )
            }}
          />
          <FunctionField
            label="Result Images"
            render={(record) => {
              if (record.resultImages?.length) {
                ;(async () => {
                  if (resultImages.length > 0) return
                  const fetchedImages = await fetchResultImages(record.kitId)
                  setResultImages(fetchedImages)
                })()

                return (
                  <>
                    <p><b>{resultImages.length}</b> images attached:</p>
                    <Carousel
                      items={resultImages.map((image) => {
                        return (
                          // eslint-disable-next-line jsx-a11y/img-redundant-alt
                          <img
                            className={image.isPatientIdCard ? 'img-thumb-patientidcard' : 'img-thumb'}
                            src={image.url}
                            onClick={() => {
                              setImagePreviewImagePath(image.url)
                              handleImagePreview(true)
                            }}
                            key={image.key}
                            alt={image.key}
                          />
                        )
                      })}
                    />

                    <p>
                      <br />
                      Images highlighted as <span className="patientidcard">Patient ID Card</span> will not be available to patient.
                      <br />
                    </p>
                  </>
                )
              }
              return <p>No Result Images Found</p>
            }}
          />
        </TabbedShowLayout.Tab>


      </TabbedShowLayout>

      <ImagePreview imagePath={imagePreviewImagePath} open={imagePreviewState} setOpen={handleImagePreview} />
    </Show>
  )
}
