import {
  useRecordContext,
  Edit,
  SaveButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  Toolbar,
  AutocompleteArrayInput,
  ReferenceArrayInput,
} from 'react-admin'

const ProviderAccessEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" />
  </Toolbar>
)

const ProviderAccessTitle = () => {
  const record = useRecordContext()
  // the record can be empty while loading
  if (!record) return null
  return <span>Provider: {record.name ?? record.email}</span>
}

export const ProviderAccessEdit = () => {
  return (
    <Edit
      title={<ProviderAccessTitle />}
      redirect={(resource, id, data) => `provider-access/${id}/show`}
      resource="provider-access/users"
      mutationMode="pessimistic"
    >
      <SimpleForm className="form-layout" toolbar={<ProviderAccessEditToolbar />}>
        <SimpleShowLayout style={{ paddingLeft: 0 }} spacing={2} className="details-page-layout">
          <TextField label="Id" source="id" />
          <TextField label="Name" source="name" emptyText="Not Added Yet" />
          <TextField label="Phone" source="phone_number" emptyText="Not Added Yet" />
          <TextField label="Email" source="email" />
          <TextField label="Email Verified" source="email_verified" emptyText="Not Added Yet" />
          <TextField label="Clinic" source="custom:clinic_name" emptyText="Not Added Yet" />
          <TextField label="Status" source="UserStatus" />
        </SimpleShowLayout>
        <ReferenceArrayInput source="providerIds" reference="providers">
          <AutocompleteArrayInput
            // source="accessibleProviders"
            fullWidth
            optionText={'displayName'}
            label="Providers (in MFH DB)"
            optionValue="providerId"
          />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  )
}
