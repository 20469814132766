import { useRecordContext } from 'react-admin'
import moment from 'moment'
export default function RequestedField() {
  const record = useRecordContext()

  if (!record.requestedAt) {
    return
  }

  return <span>{moment(record.requestedAt).local().calendar()}</span>
}
