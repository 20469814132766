import * as React from 'react'
import {
  List,
  FunctionField,
  Link,
  TextInput,
  SelectInput,
  TopToolbar,
  SelectColumnsButton,
  FilterButton,
  ReferenceInput,
  DatagridConfigurable,
  ReferenceField,
  TextField,
} from 'react-admin'
import {
  LabField,
  NotificationField,
  PatientField,
  ProviderField,
  ShipField,
  ShopifyCustomerAndLineDetail,
  StatusField,
  ToolTip,
} from '../fields/kitFields'
import { KitActions } from '../fields/helpers/kitFieldsHelpers'
import { BulkActionsKit } from '../../utils/bulkProgressKit'
import TestRecordModal from '../modals/TestRecordModal'

const KitFilters = () => {
  const filters = [
    <TextInput label="Enter Kit Id / Tracking Number" source="q" resettable alwaysOn style={{ width: '320px' }} />,
    <ReferenceInput source="providerId" reference="providers" alwaysOn>
      <SelectInput optionText={'displayName'} />
    </ReferenceInput>,
    <TextInput label="Order Number" source="shopifyOrderNumber" resettable alwaysOn style={{ width: '120px' }} />,
  ]
  if (!window.location.hash.includes('/kits/status/')) {
    filters.push(
      <SelectInput
        source="status"
        resettable
        alwaysOn
        choices={[
          {
            id: 'new',
            name: 'New',
          },
          {
            id: 'inboundShippingLabelPurchased',
            name: 'Inbound Shipping Label Purchased',
          },
          {
            id: 'unregisteredPrepaid',
            name: 'Unregistered Prepaid',
          },
          {
            id: 'outToPatient',
            name: 'Out to Patient',
          },
          {
            id: 'shippedByPatient',
            name: 'Shipped By Patient',
          },
          {
            id: 'deliveredToLab',
            name: 'Delivered to Lab',
          },
          {
            id: 'pendingConfirmation',
            name: 'Pending Confirmation',
          },
          {
            id: 'testComplete',
            name: 'Test Complete',
          },
          {
            id: 'notifyComplete',
            name: 'Notify Complete',
          },
          {
            id: 'error',
            name: 'Error',
          },
        ]}
      />
    )
  }
  return filters
}

const KitList = (props) => {
  const [openModal, setOpenModal] = React.useState(false)
  const [modalState, setModalState] = React.useState(null)

  const handleModalClose = () => {
    setOpenModal(false)
    setModalState(null)
  }

  const handleModalOpen = (record) => {
    setModalState(record)
    setOpenModal(true)
  }

  return (
    <>
      {' '}
      <List
        {...props}
        filter={{ _order: 'DESC' }}
        actions={
          <TopToolbar>
            <FilterButton />
            <SelectColumnsButton />
          </TopToolbar>
        }
        filters={KitFilters()}
      >
        <DatagridConfigurable bulkActionButtons={<BulkActionsKit />}>
          <FunctionField
            style={{ width: 'fit-content' }}
            label="KitId"
            render={(record) => <Link to={`/kits/${record.id}/show`}>{record.kitId}</Link>}
          />
          <ReferenceField label="Order" reference="orders" source="orderId">
            <TextField source="shopifyOrderNumber" label="Order" />
          </ReferenceField>
          {/* <OrderField source="orderId" /> */}
          <ToolTip />
          <ReferenceField label={false} reference="orders" source="orderId">
            <ShopifyCustomerAndLineDetail />
          </ReferenceField>
          <PatientField label="Patient Info" />
          <ProviderField label="Provider Info" />
          <StatusField label="Status" />
          <ShipField label="Ship" />
          <LabField label="Lab" />
          <NotificationField label="Notification/Views" />
          <KitActions handleModalOpen={handleModalOpen} label="Actions" />
        </DatagridConfigurable>
      </List>
      {openModal && <TestRecordModal modalState={modalState} open={openModal} handleClose={handleModalClose} />}
    </>
  )
}

export default KitList
