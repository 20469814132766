import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  TextField,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  Toolbar,
  SaveButton,
  SimpleShowLayout,
  FunctionField,
  ReferenceField,
  DateField,
  Button,
  useRecordContext,
  BooleanInput,
} from 'react-admin'
import { AdviceCodeDetailTable, formatResultRetrieval } from '../details/helpers/kitDetailsHelpers'
import { fromTimestamp, labTestAdviceCodeChoices } from '../fields/helpers/kitFieldsHelpers'
import { PatientField, ShipField } from '../fields/kitFields'
import { OrderItemsField } from '../fields/orderFields'
import { JSONToHTMLTable } from '@kevincobain2000/json-to-html-table'
import TestRecordModal from '../modals/TestRecordModal'
import React from 'react'

export const KitEdit = () => {
  const [openModal, setOpenModal] = React.useState(false)
  const [modalState, setModalState] = React.useState(null)

  const handleModalClose = () => {
    setOpenModal(false)
    setModalState(null)
  }

  const handleModalOpen = (record) => {
    setModalState(record)
    setOpenModal(true)
  }

  const KitEditToolbar = (props) => {
    const record = useRecordContext()
    return (
      <Toolbar {...props}>
        <SaveButton label="Save" />
        <Button
          style={{ marginLeft: '16px' }}
          size="medium"
          label="Update test results"
          variant="contained"
          type="button"
          onClick={() => handleModalOpen(record)}
        />
      </Toolbar>
    )
  }
  return (
    <Edit mutationMode="pessimistic">
      <SimpleForm className="form-layout" toolbar={<KitEditToolbar />}>
        <SimpleShowLayout style={{ paddingLeft: 0 }} spacing={2} className="details-page-layout">
          <TextField label="Status" source="status" />
          <TextField label="Order #" source="shopifyOrderNumber" />
          <FunctionField
            label="Order Details"
            render={() => {
              return (
                <>
                  <PatientField />
                  <ReferenceField source={'orderId'} link={false} reference="orders">
                    <OrderItemsField label="Order Items" />
                  </ReferenceField>
                </>
              )
            }}
          />
        </SimpleShowLayout>
        <TextInput label="Patient Name" source="patientName" />
        <DateInput label="Patient DoB" source="patientDoB" />
        <DateInput label="Patient Date of Vasectomy" source="patientDoV" />
        <TextInput label="Patient Email" source="patientEmail" />
        <TextInput label="Patient Phone" source="patientPhone" />
        <SelectInput
          source="patientNotificationMethod"
          choices={[
            { id: 'Text', name: 'Text' },
            { id: 'Email', name: 'Email' },
            { id: 'Other', name: 'Other' },
            { id: 'Phone', name: 'Phone' },
          ]}
        />
        <ReferenceInput source="providerId" reference="providers">
          <AutocompleteInput fullWidth optionText={'displayName'} label="Providers (in MFH DB)" optionValue="providerId" />
        </ReferenceInput>

        <SelectInput
          source="labTestAdviceCode"
          label="Lab Test Advice Code"
          choices={labTestAdviceCodeChoices.map((result) => {
            return { id: result.value, name: result.label }
          })}
        />
        <AdviceCodeDetailTable />
        <TextInput multiline rows={3} label="Kit Notes (MFH Only)" source="notes" />
        <TextInput multiline rows={3} label="Lab Test Provider Notes" source="labTestProviderNotes" />

        <SelectInput
          label="Result Retrieval Locked?"
          source="resultRetrievalLocked"
          choices={[
            { id: false, name: 'Unlocked' },
            { id: true, name: 'Locked' },
          ]}
        />

        <SimpleShowLayout style={{ paddingLeft: 0 }} spacing={2} className="details-page-layout">
          <DateField label="Specimen Collection Date " source="specimenCollectionDate" />
          <TextField source="labTestResult" label="Lab Test Result" />
          <TextField source="labTestSampleGrade" label="Lab Test Sample Grade" />

          <FunctionField
            render={(record) => {
              return formatResultRetrieval(record)
            }}
            label="Result Retrieval Code"
          />
          <TextField label="Result Retrievals" source="resultRetrievals" />
          <TextField label="Weeks Since Vasectomy" source="weeksSinceVasectomy" />
          <FunctionField
            render={(record) => record.labReceivedAt && `${fromTimestamp(record.labReceivedAt)} `}
            label="Lab Received At"
          />
          <FunctionField render={(record) => record.labTestedAt && `${fromTimestamp(record.labTestedAt)} `} label="Lab Tested At" />
          <TextField label="Lab Tested By" source="labTestedBy" />
          <FunctionField label="Inbound Shipping" render={() => <ShipField />} />
          <TextField label="Registration Label" source="sku" />
          <FunctionField
            label="Test Requisition"
            render={(record) => {
              if (record && record.testRequisition) {
                return (
                  <div>
                    <p>
                      <i>(see below)</i>
                    </p>
                    <JSONToHTMLTable data={record.testRequisition} tableClassName="detail_table " />
                  </div>
                )
              } else {
                return <div style={{ color: 'red' }}>No test requisition on file.</div>
              }
            }}
          />
          <FunctionField
            render={(record) => record.patientNotifiedAt && `${fromTimestamp(record.patientNotifiedAt)} `}
            label="Patient Last Notified"
          />
          <TextField source="patientNotifiedMethod" label="Patient Last Notified Method" />
          <TextField source="patientNotifiedBy" label="Patient Last Notified By" />
          <FunctionField
            render={(record) =>
              record.patientNotifications && <JSONToHTMLTable data={record.patientNotifications} tableClassName="detail_table " />
            }
            label="Patient Notifications"
          />
          <BooleanInput label="Can Patient View Pictures?" source="patientPictures" defaultValue={false} />
        </SimpleShowLayout>
      </SimpleForm>
      {openModal && <TestRecordModal modalState={modalState} open={openModal} handleClose={handleModalClose} edit={true} />}
    </Edit>
  )
}
