import * as React from 'react'
import {
  ArrayField,
  Datagrid,
  FunctionField,
  Link,
  ReferenceArrayField,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from 'react-admin'

export default function ProviderAccessDetail() {
  const record = useRecordContext()

  const Title = () => {
    return record && <p>Provider #{record.id}</p>
  }

  return (
    <Show resource="provider-access/users" title={<Title />}>
      <SimpleShowLayout spacing={2} className="details-page-layout">
        <TextField label="Id" source="id" />
        <TextField label="Name" source="name" emptyText="Not Added Yet" />
        <TextField label="Phone" source="phone_number" emptyText="Not Added Yet" />
        <TextField label="Email" source="email" />
        <TextField label="Email Verified" source="email_verified" emptyText="Not Added Yet" />
        <TextField label="Clinic" source="custom:clinic_name" emptyText="Not Added Yet" />
        <TextField label="Status" source="UserStatus" />
        <ReferenceArrayField reference="providers" source="providerIds">
          <Datagrid bulkActionButtons={false}>
            <FunctionField
              label="Display Name"
              render={(record) => <Link to={`/providers/${record.providerId}/show`}>{record.displayName}</Link>}
            />
          </Datagrid>
        </ReferenceArrayField>
      </SimpleShowLayout>
    </Show>
  )
}
