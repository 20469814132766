import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import { Button, useListContext, useNotify, useRefresh, useUnselectAll } from 'react-admin'
import {
  canMarkAsNotifyComplete,
  canNotifyPatient,
  canNotifyProvider,
  canReNotifyPatient,
  markAsNotifyComplete,
  notifyPatient,
  notifyProviderFax,
  patientHasBeenNotified,
  providerHasBeenNotified,
} from './progressKit'

export const BulkActionsKit = () => {
  const { selectedIds, data } = useListContext()
  const refresh = useRefresh()
  const notify = useNotify()
  const unselectAll = useUnselectAll('kits')
  const [actionMap, setActionMap] = useState({
    // isStatus: [],
    // canTest: [],
    // isTestComplete: [],
    canNotifyPatient: [],
    canReNotifyPatient: [],
    canNotifyProvider: [],
    // canGetRegLabel: [],
    canMarkAsNotifyCompleteFull: [],
    canMarkAsNotifyCompletePartial: [],
    // patientHasBeenNotified: [],
    // providerHasBeenNotified: [],
  })

  useEffect(() => {
    setActionMap(updateActionMap(selectedIds, data))
  }, [selectedIds])
  return (
    <Box display={'flex'} flexDirection="row" flexWrap={'wrap'} alignItems={'center'} columnGap={'16px'} width="inherit">
      {actionMap.canNotifyPatient.length !== 0 && (
        <button
          className="btn btn-info"
          type="button"
          onClick={async () => {
            await notifyPatient(undefined, actionMap.canNotifyPatient, notify, refresh)

            unselectAll()
            refresh()
          }}
        >
          <p>
            <span className="fa fa-archive"></span> Send Results Link to Patient ({actionMap.canNotifyPatient.length})
          </p>
        </button>
      )}
      {actionMap.canReNotifyPatient.length !== 0 && (
        <button
          type="button"
          className="btn btn-info"
          onClick={async () => {
            await notifyPatient(true, actionMap.canReNotifyPatient, notify, refresh)

            unselectAll()
            refresh()
          }}
        >
          <p>
            <span className="fa fa-archive"></span> Re-send Results Link to Patient ({actionMap.canReNotifyPatient.length})
          </p>
        </button>
      )}

      {actionMap.canMarkAsNotifyCompleteFull.length !== 0 && (
        <button
          type="button"
          className="btn btn-success"
          onClick={async () => {
            await markAsNotifyComplete(actionMap.canMarkAsNotifyCompleteFull, notify, refresh)

            unselectAll()
            refresh()
          }}
        >
          <p>
            <span className="fa fa-archive"></span> Mark as Fully Notified ({actionMap.canMarkAsNotifyCompleteFull.length})
          </p>
        </button>
      )}

      {actionMap.canMarkAsNotifyCompletePartial.length !== 0 && (
        <button
          type="button"
          className="btn btn-default"
          onClick={async () => {
            await markAsNotifyComplete(actionMap.canMarkAsNotifyCompletePartial, notify, refresh)

            unselectAll()
            refresh()
          }}
        >
          <p>
            <span className="fa fa-archive"></span> Mark as Partially Notified ({actionMap.canMarkAsNotifyCompletePartial.length})
          </p>
        </button>
      )}

      {actionMap.canNotifyProvider.length !== 0 && (
        <button
          type="button"
          className="btn btn-warning"
          onClick={async () => {
            await notifyProviderFax(actionMap.canNotifyProvider, notify, refresh)

            unselectAll()
            refresh()
          }}
        >
          <p>
            <span className="fa-solid fa-fax"></span> Fax Result to Provider ({actionMap.canNotifyProvider.length})
          </p>
        </button>
      )}
    </Box>
  )
}

const updateActionMap = (selectedIds, data) => {
  const actionMap = {
    // isStatus: [],
    // canTest: [],
    // isTestComplete: [],
    canNotifyPatient: [],
    canReNotifyPatient: [],
    canNotifyProvider: [],
    // canGetRegLabel: [],
    canMarkAsNotifyCompleteFull: [],
    canMarkAsNotifyCompletePartial: [],
    // patientHasBeenNotified: [],
    // providerHasBeenNotified: [],
  }
  const filteredRecords = data.filter((d) => selectedIds.includes(d.id))

  filteredRecords.forEach((record) => {
    if (canNotifyPatient(record)) {
      actionMap.canNotifyPatient.push(record)
    }
    if (canReNotifyPatient(record)) {
      actionMap.canReNotifyPatient.push(record)
    }
    if (canMarkAsNotifyComplete(record) && patientHasBeenNotified(record) && providerHasBeenNotified(record)) {
      actionMap.canMarkAsNotifyCompleteFull.push(record)
    }
    if (canMarkAsNotifyComplete(record) && (!patientHasBeenNotified(record) || !providerHasBeenNotified(record))) {
      actionMap.canMarkAsNotifyCompletePartial.push(record)
    }
    if (canNotifyProvider(record)) {
      actionMap.canNotifyProvider.push(record)
    }
  })

  return actionMap
}
