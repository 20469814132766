import { useNotify, useRecordContext, useRefresh } from 'react-admin'
import {
  canAssignKits,
  canAssignBulkKits,
  canShipViaShopify,
  canCreateAllShippingLabels,
  canMarkAsFulfilled,
  canMarkAsManuallyFulfilled,
  formatMFHLineItemAndPatientDetail,
  getMFHPatientDetailFromLineItem,
  getOutboundShippingInfo,
  isShippingLabelPurchased,
} from './helpers/orderFieldsHelpers'
import { Box, Button, Link } from '@mui/material'
import { assignKits, createAllShippingLabels, markAsShipped, printAllShippingLabels } from '../../utils/progressOrderActions'

export const OrderCustomerDetailField = (props) => {
  const record = useRecordContext(props)
  return record ? (
    <div style={{ background: '#f0ad4e26', padding: '0.5em', paddingBottom: '-0.5em', solid: '#f0ad4ea8' }}>
      <p>
        <strong>
          {record.shopifyData.customer?.first_name} {record.shopifyData.customer?.last_name}
        </strong>
      </p>
      <p>
        <strong>Email:</strong> {record.shopifyData.customer?.email ?? 'No Email Found'}
      </p>
      <p>
        <strong>Phone:</strong>{' '}
        {record.shopifyData.phone ||
          record.shopifyData.customer?.phone ||
          record.shopifyData.billing_address?.phone ||
          record.shopifyData.shipping_address?.phone}
      </p>
      <p>
        {record.shopifyData.shipping_address?.city ||
            record.shopifyData.billing_address?.city ||
            '-'
        }, 
        {record.shopifyData.shipping_address?.province_code ||
            record.shopifyData.billing_address?.province_code ||
            '-'
        }
      </p>
      <p>
        ${record.shopifyData.total_price}{' '}
        <span style={{ color: record.shopifyData.financial_status !== 'paid' ? 'red' : 'black' }}>
          ({record.shopifyData.financial_status})
        </span>
      </p>
      <p>{new Date(record.shopifyData.created_at).toLocaleString()}</p>
    </div>
  ) : null
}

export const OrderItemsField = (props) => {
  const record = useRecordContext(props)
  if (!record) return null

  const line_items = record.shopifyData.line_items

  return line_items.map((item, index) => {
    const MFHPatientDetail = getMFHPatientDetailFromLineItem(item)
    return (
      <>
      <div key={index}>{formatMFHLineItemAndPatientDetail(item, MFHPatientDetail)}</div>
      </>
    )
  })
}

export const OrderOutBoundShippingField = (props) => {
  const record = useRecordContext(props)

  if (!record) {
    return null
  }

  const output = getOutboundShippingInfo(record)

  return output.map((o, index) => (
    <Box key={index} display={'flex'} rowGap="16px" flexDirection="column" alignItems={'start'} width="max-content">
      {o}
    </Box>
  ))
}

export const ProgressOrderActions = (props) => {
  const record = useRecordContext(props)
  const notify = useNotify()
  const refresh = useRefresh()

  return (
    <Box display={'flex'} flexDirection="column" alignItems={'start'} width="max-content">
      {/* Kit Creation/Assignment */}

      {canAssignBulkKits(record) && (
        <Button
          type="button"
          onClick={async () => {
            await assignKits([record], notify)
            refresh()
          }}
        >
          <p>
            <span className="fa fa-boxes"></span> Assign Bulk Kits
          </p>
        </Button>
      )}

      {/* Kit Creation/Assignment */}

      {canAssignKits(record) && (
        <Button
          type="button"
          onClick={async () => {
            await assignKits([record], notify)
            refresh()
          }}
        >
          <p>
            <span className="fa fa-archive"></span> Assign Kits
          </p>
        </Button>
      )}

      {/* Do we need to send the user to Shopify Admin to ship this order? */}
      {canShipViaShopify(record) && (
        <Button
          href={`https://admin.shopify.com/store/malefromhome-com/orders/${record['orderId']}`}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-success"
          >
            <span className="fa fa-shipping-fast" title="Ship via Shopify"></span>
            Ship via Shopify
            &nbsp;
            <i className="fa-solid fa-up-right-from-square"></i>
        </Button>
      )}


      {/* Create Shipping Label */}

      {canCreateAllShippingLabels(record) && (
        <Button
          type="button"
          onClick={async () => {
            await createAllShippingLabels([record], notify)
            refresh()
          }}
        >
          <p>
            <span className="fa fa-barcode"></span> Create Shipping Labels
          </p>
        </Button>
      )}

      {/* Print Labels: Mark as Shipped */}

      {isShippingLabelPurchased(record) && (
        <>
          <Button
            type="button"
            onClick={async () => {
              await printAllShippingLabels([record], notify)
              refresh()
            }}
          >
            <p>
              <span className="fa fa-file-pdf-o"></span> Print All Labels
            </p>
          </Button>

          <Button
            type="button"
            onClick={async () => {
              await markAsShipped([record], notify, true, refresh)
              refresh()
            }}
          >
            <p>
              <span className="fa fa-envelope"></span> Mark as Shipped
            </p>
          </Button>
        </>
      )}
      {/* Mark as Fulfilled (no shipping)  */}

      {canMarkAsFulfilled(record) && (
        <Button
          type="button"
          onClick={async () => {
            await markAsShipped([record], notify, false, refresh)
            refresh()
          }}
        >
          <p>
            <span className="fa fa-check"></span> Mark as Fulfilled
          </p>
        </Button>
      )}

      {/* Mark as MANUALLY fulfilled (no shipping) */}
      {canMarkAsManuallyFulfilled(record) && (
        <Button
          type="button"
          onClick={async () => {
            await markAsShipped([record], notify, true, refresh)
            refresh()
          }}
        >
          <p>
            <span className="fa fa-check"></span> Mark as Manually Fulfilled
          </p>
        </Button>
      )}
    </Box>
  )
}
