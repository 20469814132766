import * as React from 'react'
import {
  List,
  TextField,
  FunctionField,
  DatagridConfigurable,
  TextInput,
  TopToolbar,
  useNotify,
  SelectColumnsButton,
  ShowButton,
  EditButton,
  CreateButton,
} from 'react-admin'
import { Link } from 'react-router-dom'
import ProviderUtil from '../../utils/providerUtils'

const ProviderList = (props) => {
  const notify = useNotify()
  const ProviderFilters = [<TextInput label="Provider Name or Clinic" source="q" alwaysOn />]

  return (
    <List
      actions={
        <TopToolbar>
          <ProviderUtil notify={notify} />
          <SelectColumnsButton />
          <CreateButton />
        </TopToolbar>
      }
      {...props}
      hasCreate={true}
      filter={{ _order: 'DESC' }}
      filters={ProviderFilters}
    >
      <DatagridConfigurable bulkActionButtons={<></>}>
        <FunctionField
          label="Display Name"
          render={(record) => <Link to={`/providers/${record.id}/show`}>{record.displayName}</Link>}
        />
        <TextField source="fax" label="Fax" />
        <TextField source="faxPositiveResults" label="Fax - Positive" />
        <TextField source="faxNegativeResults" label="Fax - Negative" />
        <TextField source="status" label="Status" />
        <FunctionField
          label="Action"
          render={(record) => (
            <div style={{ display: 'flex', columnGap: '8px' }}>
              <Link to={{ pathname: '/notifications', search: `filter=${JSON.stringify({ q: record.providerId })}` }}>
                <button className="btn btn-success text-white">
                  <span className="fa-solid fa-fax"></span> View Notifications
                </button>
              </Link>
              <ShowButton label="Show" />
              <EditButton label="Edit" />
            </div>
          )}
        />
      </DatagridConfigurable>
    </List>
  )
}

export default ProviderList
