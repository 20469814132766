import { Button } from 'react-admin'
import { client } from '../providers/data/client'

export default function RefreshOrderButton({ notify, refresh, setLoading }) {
  return (
    <Button type="button" className="btn btn-default" onClick={() => refreshOrders(notify, refresh, setLoading)}>
      <span className="fa-solid fa-barcode" aria-hidden="true"></span>&nbsp;Refresh Orders from Shopify
    </Button>
  )
}

const refreshOrders = async (notify, refresh, setLoading) => {
  let refreshMsg = 'Unknown'

  notify('Shopify Order Refresh starting ...', {
    type: 'success',
    multiline: true,
  })

  try {
    setLoading(true)
    const response = await client().post('/util/refreshOrders', {
      status: 'any',
      updated_at_min: '2018-09-20T00:00:00-07:00',
      limit: 100,
    })

    let nNew = 0
    let nUpdated = 0
    response.data.orderMetaData.forEach(function (omd) {
      nUpdated += omd.updated ? 1 : 0
      nNew += omd.inserted ? 1 : 0
    })
    refreshMsg = 'Shopify Order Refresh complete\n\nNew orders: ' + nNew + '\nUpdated orders: ' + nUpdated

    setLoading(false)
    refresh()
    notify(refreshMsg, {
      type: 'success',
      multiline: true,
    })
  } catch (error) {
    console.log(error)
    notify('A problem occurred while trying to refresh orders, please try again', {
      type: 'error',
      multiline: true,
    })
    setLoading(false)
  }
}
