import { useRecordContext, Edit, SaveButton, SelectInput, SimpleForm, SimpleShowLayout, TextField, TextInput, Toolbar } from 'react-admin'

const ProviderEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" />
  </Toolbar>
)

const ProviderTitle = () => {
  const record = useRecordContext();
  // the record can be empty while loading
  if (!record) return null;
  return <span>Provider: {record.displayName}</span>;
}

const makeProviderName = (entry) => {
  return (
    entry.lastName +
    /* (entry.suffixName ? ' ' + entry.suffixName : '') + */
    ', ' +
    entry.firstName +
    (entry.middleName ? ' ' + entry.middleName : '')
  )
}

const transform = (data) => ({
  ...data,
  displayName: makeProviderName(data) + ' (' + data.clinicName + ')',
})

export const ProviderEdit = () => (
  <Edit title={<ProviderTitle />}
    mutationMode="pessimistic" transform={transform}>
    <SimpleForm className="form-layout" toolbar={<ProviderEditToolbar />}>
      <SimpleShowLayout style={{ paddingLeft: 0 }} spacing={2} className="details-page-layout">
        <TextField label="Display Name" source="displayName" />
      </SimpleShowLayout>
      <SelectInput
        label="Status"
        source="status"
        choices={[
          {
            id: 'pending',
            name: 'Pending',
          },
          {
            id: 'active',
            name: 'Active',
          },
          {
            id: 'inactive',
            name: 'Inactive',
          },
        ]}
      />
      <TextInput label="Last Name" source="lastName" />
      <TextInput label="First Name" source="firstName" />
      <TextInput label="Middle Name/Initial" source="middleName" />
      <TextInput label="Name Suffix" source="suffixName" />
      <TextInput label="Clinic" source="clinicName" />
      <TextInput label="Address 1" source="address1" />
      <TextInput label="Address 2" source="address2" />
      <TextInput label="City" source="city" />
      <TextInput label="State" source="state" />
      <TextInput label="Zip" source="zip" />
      <TextInput label="Email" source="email" />
      <TextInput label="Phone" source="phone" />
      <TextInput label="Fax" source="fax" />
      <TextInput label="Website" source="website" />
      <TextInput label="Admin/Asst Name" source="adminContact" />
      <TextInput label="Admin/Asst Email" source="adminEmail" />
      <TextInput label="Admin/Asst Phone" source="adminPhone" />
      <SelectInput
        choices={[
          {
            id: 'Yes',
            name: 'Yes',
          },
          {
            id: 'No',
            name: 'No',
          },
        ]}
        label="Fax Positive Results?"
        source="faxPositiveResults"
      />
      <SelectInput
        choices={[
          {
            id: 'Yes',
            name: 'Yes',
          },
          {
            id: 'No',
            name: 'No',
          },
        ]}
        label="Fax Negative Results?"
        source="faxNegativeResults"
      />
      <TextInput label="Notes" multiline={true} source="notes" />
    </SimpleForm>
  </Edit>
)
