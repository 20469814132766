import { Box, Modal, Typography } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email';
import Chip from '@mui/material/Chip'
import * as React from 'react'
import {
  List,
  DatagridConfigurable,
  TextField,
  ReferenceArrayField,
  SingleFieldList,
  FunctionField,
  SimpleForm,
  TextInput,
  required,
  TopToolbar,
  Button,
  Create,
  useRefresh,
  Link,
  EditButton,
  useNotify,
} from 'react-admin'
import { client } from '../../providers/data/client'
import Empty from '../common/Empty'
import moment from 'moment'

const ProviderAccessList = (props) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const refresh = useRefresh()
  const notify = useNotify()

  const handleCreate = async (email, resend = false) => {
    try {
      notify('(Re)sending Invitation', { type: 'info', undoable: false })
      const result = await client().post('/provider-access/users', { email })

      if (result) {
        setIsOpen(false)
        notify(resend ? 'Invitation resent' : 'Invitation sent', { type: 'success', undoable: false })
        refresh()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const CreateModal = () => {
    return (
      <Modal className="modal" open={isOpen} onClose={() => setIsOpen(false)}>
        <Box className="modalContainer">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Invite a user to access Provider Portal:
          </Typography>
          <Create
            resource="provider-access/users"
            redirect={false}
            sx={{ height: '100%' }}
          >
            <SimpleForm onSubmit={(event) => handleCreate(event.email)}>
              <TextInput source="email" type="email" label="Email Address" validate={[required()]} fullWidth />
            </SimpleForm>
          </Create>
        </Box>
      </Modal>
    )
  }

  const handleModalOpen = () => setIsOpen(true)
  return (
    <>
      <List
        resource="provider-access/users"
        {...props}
        actions={
          <TopToolbar>
            <Button
              label="Send Invite"
              children={<EmailIcon />}
              variant="contained"
              color="primary"
              onClick={handleModalOpen} />
          </TopToolbar>
        }
        filter={{ _order: 'DESC' }}
        empty={
          <Empty
            create={true}
            CreateComponent={() => <Button variant="contained" label="Send Invite" size="medium" onClick={handleModalOpen} />}
          />
        }
      >
        <DatagridConfigurable bulkActionButtons={false}>
          <FunctionField label="Email" render={(record) => <Link to={`/provider-access/${record.id}/show`}>{record.email}</Link>} />
          <FunctionField
            label="User Info"
            render={(record) => {
              let emailVerified = <b>Yes</b>
              if ( ! record['Attributes'].find(attr => attr.Name === "email_verified")?.Value ) {
                emailVerified = <Chip label="No" color="error" />
              }
              return (
                <div>
                  <div style={{ background: '#f0ad4e26', padding: '4px' }}>
                    <p>
                      Name: <b>{record.name ?? 'Not Added'}</b>
                    </p>
                    <p>
                      Phone: <b>{record.phone_number ?? 'Not Added'}</b>
                    </p>
                    <p>
                      Clinic: <b>{record['custom:clinic_name'] ?? 'Not Added'}</b>
                    </p>
                  </div>
                  <div style={{ padding: '4px' }}>
                    <p>
                      Email Verified: {emailVerified}
                    </p>
                  </div>
                  <div style={{ padding: '4px' }}>
                    <p>
                      Status: <b>{record['UserStatus'] ?? 'Unknown'}</b>
                    </p>
                  </div>
                </div>
              )
            }}
          />
          <FunctionField
            label="Last logged in at"
            render={(record) => (record.lastLoggedIn ? moment(record.lastLoggedIn).format('YYYY-MM-DD HH:mm:ss') : null)}
          />
          <ReferenceArrayField label="Providers" source="providers" reference="providers">
            <SingleFieldList style={{ display: 'flex', flexFlow: 'column', rowGap: '2px' }}>
              <TextField source="displayName" />
            </SingleFieldList>
          </ReferenceArrayField>
          <FunctionField
            label={'Actions'}
            render={(record) =>
              <div>
              {record['UserStatus'] === 'FORCE_CHANGE_PASSWORD' ? (
                <Button
                  label="Resend Invite"
                  children={<EmailIcon />}
                  variant="contained"
                  onClick={() => handleCreate(record.email, true)}
                />
              ) : null
            }
            <EditButton sx={{marginTop: '4px'}} variant="outlined" resource="provider-access" />
            </div>          
        }
          />
        </DatagridConfigurable>
      </List>
      <CreateModal />
    </>
  )
}

export default ProviderAccessList
