import { useRecordContext, ReferenceField, FunctionField } from 'react-admin'
import CameraAltTwoToneIcon from '@mui/icons-material/CameraAltTwoTone';
import Badge from '@mui/material/Badge'
import Tooltip from '@mui/material/Tooltip'
import moment from 'moment'
import {
  ShopifyLineSummary,
  adviceCodeLabel,
  formatKitShipping,
  formatProviders,
  labTestResultLabel,
  patientPicturesField,
  patientNotificationDetails,
  providerNotificationDetails,
  showRequisitionForKit,
  weeksSinceVasectomyLabel,
} from './helpers/kitFieldsHelpers'
// StatusField converts an internal statusCode to a display ready string.
// i.e. a status of notifyComplete will be rendered by this field as 'Notify Complete'
export const StatusField = () => {
  const record = useRecordContext()
  var kitStatusChoices = {
    new: {
      label: 'New',
    },
    inboundShippingLabelPurchased: {
      label: 'Inbound Shipping Label Purchased',
    },
    unregisteredPrepaid: {
      label: 'Unregistered Prepaid',
    },
    outToPatient: {
      label: 'Out to Patient',
    },
    shippedByPatient: {
      label: 'Shipped By Patient',
    },
    deliveredToLab: {
      label: 'Delivered to Lab',
    },
    pendingConfirmation: {
      label: 'Pending Confirmation',
    },
    testComplete: {
      label: 'Test Complete',
    },
    notifyComplete: {
      label: 'Notify Complete',
    },
    manualReview: {
      label: 'Manual Review',
    },
    error: {
      label: 'Error',
    },
  }
  return (
    record.status && (
      <div>
        <p>{kitStatusChoices[record.status].label}</p>
      </div>
    )
  )
}

export const ShipField = () => {
  const record = useRecordContext()

  var status = record.inboundShippingStatus || ''

  if (record.inboundShippingStatusAt) {
    status = status + ' ' + moment(record.inboundShippingStatusAt).local().format('YYYY-MM-DD h:mmA z')
  }

  return (
    record && (
      <div>
        {formatKitShipping(record)}
        <p>{status}</p>
      </div>
    )
  )
}

export const LabField = () => {
  const record = useRecordContext()
  return (
    <div className="lab-field-kits">
      {showRequisitionForKit(record)}
      <div>{labTestResultLabel(record)}</div>
      <div>{weeksSinceVasectomyLabel(record)}</div>
      <div>{adviceCodeLabel(record)}</div>
    </div>
  )
}

export const ToolTip = () => {
  const record = useRecordContext()

  if ( ! record ) {
    return (
    <span></span>
    )
  }

  let resultImagesCount = ''
  let kitNotes = ''
  let providerNotes = ''

  if ( record.resultImages && record.resultImages.length > 0 ) {
    resultImagesCount = (
      <div>
        <Tooltip title={`${record.resultImages.length} image(s)`} >
          <Badge badgeContent={record.resultImages.flat().length} color="primary">
            <CameraAltTwoToneIcon fontSize="small" color="action" />
          </Badge>
        </Tooltip>
      </div>
    )
  }

  if ( record.notes ) {
    kitNotes = (
      <span style={{ color: '#3296F3', padding: '2px' }} className="fa-solid fa-file tooltip">
        <span className="tooltiptext">{record.notes && record.notes.replace(/"/g, '&quot;')}</span>
      </span>
    )
  }

  if ( record.labTestProviderNotes ) {
    providerNotes = (
      <span style={{ color: '#ff2f2f', padding: '2px' }} className="fa-solid fa-file-powerpoint tooltipprovider">
        <span className="tooltipprovidertext">
          {record.labTestProviderNotes && record.labTestProviderNotes.replace(/"/g, '&quot;')}
        </span>
      </span>
    )
  }

  return (
    <span>
      {resultImagesCount}
      {kitNotes}
      {providerNotes}
    </span>
  )
}

export const ShopifyCustomerAndLineDetail = function () {
  const order = useRecordContext()

  // Get name, email, phone of shopify customer on this order
  if (!order || !order.shopifyOrderNumber) {
    return '-'
  }

  var fn = order.shopifyData.customer.first_name
  var ln = order.shopifyData.customer.last_name
  var cust = fn + ' ' + ln
  var email = order.shopifyData.email || (order.shopifyData.customer ? order.shopifyData.customer.email : null) || '-'
  var phone =
    order.shopifyData.phone ||
    order.shopifyData.customer.phone ||
    (order.shopifyData.shipping_address ? order.shopifyData.shipping_address.phone : null) ||
    (order.shopifyData.billing_address ? order.shopifyData.billing_address.phone : null) ||
    '-'
  var custCity =
    (order.shopifyData.shipping_address ? order.shopifyData.shipping_address.city : null) ||
    (order.shopifyData.billing_address ? order.shopifyData.billing_address.city : null) ||
    '-'
  var custState =
    (order.shopifyData.shipping_address ? order.shopifyData.shipping_address.province_code : null) ||
    (order.shopifyData.billing_address ? order.shopifyData.billing_address.province_code : null) ||
    '-'
  var orderDate = moment(order.shopifyData.created_at).local().format('MM/DD/YYYY')

  // Show an icon, with all the details (as text) in a "mouseOver"

  // Render Text

  var textDetail = ` Customer: ${cust}\nEmail: ${email}\nPhone: ${phone}\nCity: ${custCity}, ${custState}\n---------------------\nOrder Date: ${orderDate}\n${ShopifyLineSummary(
    order
  )}`

  return (
    <span className="fa-solid fa-money-bill tooltip" aria-hidden="true">
      <p className="tooltiptext">{textDetail}</p>
    </span>
  )
}

// OrderField handles displaying the OrderId of an ordered whether it's a prepaid order or a regular order
// If a prepaidId is associated with the kit, then it assumes it's a prepaidOrder and reads that data for display
// otherwise, it will display the orderId
export const OrderField = () => {
  const record = useRecordContext()
  // if (record && record['shopifyOrderNumber']) {
  //   return (
  //     <ReferenceField label="Order" source={'prepaidOrderId'} reference="orders">
  //       <span>{record['shopifyOrderNumber']}</span>
  //     </ReferenceField>
  //   )
  // }

  return (
    <ReferenceField label="Order" source={'orderId'} reference="orders">
      <span>{record['shopifyOrderNumber']}</span>
    </ReferenceField>
  )
}

// PatientField shows up to all of the elements in displayValues. If a value in displayValues doesn't have
// a value in the kit object, it will skip rendering that entry in the Patient Info box
export const PatientField = () => {
  const record = useRecordContext()
  return (
    record && (
      <div style={{ background: '#f0ad4e26', padding: '0.5em', paddingBottom: '-0.5em', solid: '#f0ad4ea8' }}>
        <p>
          <strong>{record.patientName}</strong>
        </p>
        <p>
          <strong>{record.patientDoB}</strong>
        </p>
        <p>
          <strong>Email: </strong>
          {record.patientEmail ?? ''}
        </p>
        <p>
          <strong>Phone: </strong>
          {record.patientPhone}
        </p>
        <p>
          <strong>Notify: </strong>
          {record.patientNotificationMethod}
        </p>
        <p>
          <strong>DoV: </strong>
          {record.patientDoV}
        </p>
      </div>
    )
  )
}

export const ProviderField = () => {
  const record = useRecordContext()
  const providerNameOther = record.providerNameOther
  return (
    <ReferenceField source="providerId" reference="providers">
      <FunctionField render={(record) => formatProviders(record, providerNameOther)} />
    </ReferenceField>
  )
}

export const NotificationField = () => {
  const record = useRecordContext()

  return (
    <div>
      <p>{patientPicturesField(record)}</p>
      {patientNotificationDetails(record)}
      <p>{providerNotificationDetails(record)}</p>
    </div>
  )
}

// order actions
// new -> assign kits
// kitsAssigned -> create shipping labels
// shippingLabelsPurchased -> print all labels; mark as shipped
