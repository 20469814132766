import { FunctionField, useRecordContext } from 'react-admin'
import { JSONToHTMLTable } from '@kevincobain2000/json-to-html-table'

export const Title = () => {
  const record = useRecordContext()

  return record && <p>Order #{record.shopifyOrderNumber}</p>
}

export function OutBoundShipmentTracking() {
  return (
    <FunctionField
      label="Outbound Shipment Tracking"
      render={(record) => {
        var ep = '',
          usps = '',
          info = ''

        if (record.noOutboundShipping) {
          // No outbound shipping needed on this order
          return <i>None</i>
        }
        if (record.shippingTrackingUrl) {
          ep = (
            <p>
              record.shippingTrackingNumber{' '}
              <a href={record.shippingTrackingUrl} target="_blank" rel="noreferrer">
                EasyPost
              </a>
            </p>
          )
        }
        if (record.shippingTrackingNumber) {
          usps = (
            <a
              href={`https://tools.usps.com/go/TrackConfirmAction?tLabels=${record.shippingTrackingNumber}`}
              target="_blank"
              rel="noreferrer"
            >
              USPS
            </a>
          )
        } else {
          info =
            'Generate a shipping label, or manually enter a tracking number to update order status to "Shipping Label Purchased".'
        }

        return (
          <p>
            ( {ep} | {usps} ) <br /> {info}
          </p>
        )
      }}
    />
  )
}

export function ShopifyRawData() {
  const record = useRecordContext()

  return (
    record && (
      <div>
        <h3>Shopify Raw Data</h3>
        <JSONToHTMLTable border={2} data={record.shopifyData} tableClassName="detail_table " />
      </div>
    )
  )
}
