import {
  CognitoIdentityProviderClient,
  InitiateAuthCommand,
  RespondToAuthChallengeCommand,
  ForgotPasswordCommand,
  ConfirmForgotPasswordCommand,
  GlobalSignOutCommand,
} from '@aws-sdk/client-cognito-identity-provider'
import poolData from '../../cognito-pool-data'

const client = new CognitoIdentityProviderClient({ region: poolData.Region })

const cognitoProvider = {
  login: ({ username, password, newPassword = undefined }) => {
    const params = {
      AuthFlow: 'USER_PASSWORD_AUTH',
      ClientId: poolData.ClientId,
      AuthParameters: {
        USERNAME: username,
        PASSWORD: password,
      },
    }

    return new Promise(async (resolve, reject) => {
      try {
        const command = new InitiateAuthCommand(params)
        const response = await client.send(command)

        if (response.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
          if (newPassword) {
            const challengeResponse = new RespondToAuthChallengeCommand({
              ClientId: poolData.ClientId,
              ChallengeName: 'NEW_PASSWORD_REQUIRED',
              ChallengeResponses: {
                USERNAME: username,
                NEW_PASSWORD: newPassword,
              },
              Session: response.Session,
            })

            const newPasswordResponse = await client.send(challengeResponse)

            localStorage.setItem('accessToken', newPasswordResponse.AuthenticationResult.AccessToken)
            localStorage.setItem('idToken', newPasswordResponse.AuthenticationResult.IdToken)
            localStorage.setItem('cognito_username', username)
            resolve()
          } else {
            reject({
              changeTempPassword: true,
              user: { username, password },
            })
          }
          return
        }

        const { AuthenticationResult } = response
        localStorage.setItem('accessToken', AuthenticationResult.AccessToken)
        localStorage.setItem('idToken', AuthenticationResult.IdToken)
        localStorage.setItem('cognito_username', username)
        resolve()
      } catch (error) {
        console.error(error)
        reject(error)
      }
    })
  },

  logout: async () => {
    try {
      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        const command = new GlobalSignOutCommand({
          AccessToken: accessToken,
        })
        await client.send(command)
      }
    } catch (error) {
      console.error('Logout error:', error)
    } finally {
      localStorage.clear()
      return Promise.resolve()
    }
  },

  checkError: ({ status }) => {
    if (status === 401) {
      localStorage.clear()
      return Promise.reject({ redirectTo: '/login' })
    }
    return Promise.resolve()
  },

  checkAuth: async () => {
    const accessToken = localStorage.getItem('accessToken')
    const idToken = localStorage.getItem('idToken')

    if (!accessToken || !idToken) {
      return Promise.reject({ redirectTo: '/login' })
    }

    // Basic token validation (you might want to add more sophisticated validation)
    try {
      // Parse the JWT token to check expiration
      const payload = JSON.parse(atob(accessToken.split('.')[1]))
      if (payload.exp * 1000 < Date.now()) {
        localStorage.clear()
        return Promise.reject({ redirectTo: '/login' })
      }
      return Promise.resolve()
    } catch (error) {
      localStorage.clear()
      return Promise.reject({ redirectTo: '/login' })
    }
  },

  getPermissions: () => Promise.resolve(),

  getIdentity: () =>
    Promise.resolve({
      fullName: localStorage.getItem('cognito_username'),
    }),

  forgotPassword: ({ username }) => {
    const params = {
      ClientId: poolData.ClientId,
      Username: username,
    }

    return new Promise(async (resolve, reject) => {
      try {
        const command = new ForgotPasswordCommand(params)
        await client.send(command)
        resolve()
      } catch (error) {
        reject(error.message)
      }
    })
  },

  confirmPassword: ({ username, code, newPassword }) => {
    const params = {
      ClientId: poolData.ClientId,
      Username: username,
      ConfirmationCode: code,
      Password: newPassword,
    }

    return new Promise(async (resolve, reject) => {
      try {
        const command = new ConfirmForgotPasswordCommand(params)
        await client.send(command)
        resolve()
      } catch (error) {
        reject(error.message)
      }
    })
  },
}

export default cognitoProvider
