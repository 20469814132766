import * as React from 'react'
import { useRecordContext, Show, SimpleShowLayout, TextField, FunctionField } from 'react-admin'
import { Title } from './helpers/providerDetailsHelpers'

const ProviderTitle = () => {
  const record = useRecordContext();
  // the record can be empty while loading
  if (!record) return null;
  return <span>Provider: {record.displayName}</span>;
}

export default function ProviderDetails() {
  return (
    <Show title={<ProviderTitle />}>
      <SimpleShowLayout spacing={2} className="details-page-layout">
        <TextField label="Provider Id" source="providerId" />
        <TextField label="Display Name" source="displayName" />
        <TextField label="Status" source="status" />
        <TextField label="Last Name" source="lastName" />
        <TextField label="First Name" source="firstName" />
        <TextField label="Middle Name/Initial" source="middleName" />
        <TextField label="Name Suffix" source="suffixName" />
        <TextField label="Clinic" source="clinicName" />
        <TextField label="Address 1" source="address1" />
        <TextField label="Address 2" source="address2" />
        <TextField label="City" source="city" />
        <TextField label="State" source="state" />
        <TextField label="Zip" source="zip" />
        <TextField label="Email" source="email" />
        <TextField label="Phone" source="phone" />
        <TextField label="Fax" source="fax" />
        <TextField label="Website" source="website" />
        <TextField label="Admin/Asst Name" source="adminContact" />
        <TextField label="Admin/Asst Email" source="adminEmail" />
        <TextField label="Admin/Asst Phone" source="adminPhone" />
        <TextField label="Fax Positive Results?" source="faxPositiveResults" />
        <TextField label="Fax Negative Results?" source="faxNegativeResults" />
        <FunctionField
          label="Notes"
          render={(record) => {
            return record && record.notes ? record.notes.replace(/(<([^>]+)>)/gi, '') : record.notes
          }}
        />
      </SimpleShowLayout>
    </Show>
  )
}
