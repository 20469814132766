import { Button } from 'react-admin'
import { client } from '../providers/data/client'

export const defaultProviderId = '00000000-0000-0000-0000-000000000000'

export default function ProviderUtil({ notify }) {
  return (
    <Button type="button" onClick={() => getProviderList(notify)}>
      <span className="fa-solid fa-list-ul"></span>&nbsp;Get Active Provider List For Shopify
    </Button>
  )
}

const getProviderList = async (notify) => {
  let result = ''

  notify('Creating provider list ...', {
    type: 'success',
    multiline: true,
  })
  try {
    const response = await client().get(`/providers?status=active`)

    response.data.data.forEach(function (p) {
      // Remove defaultProviderId
      if (p.providerId !== defaultProviderId) {
        result += p.displayName + '<br />\n'
      }
    })

    // Add "Other" and "No Provider"
    result += '*Other Provider (Enter Below)*<br />\n'
    result += '*No Provider (Just notify me of the results)*<br />\n'

    var winPrint = window.open('', '', 'left=0,top=0,width=800,height=600,toolbar=0,scrollbars=0,status=0')
    winPrint.document.write(result)
    winPrint.document.close()

    notify('Opened new window', {
      type: 'success',
      multiline: true,
    })
  } catch (error) {
    console.log(error)
    notify('A problem occurred while trying to generate provider list, please try again', {
      type: 'error',
      multiline: true,
    })
  }
}
