import React from 'react'

function ImagePreview({ imagePath, open, setOpen }) {
  return (
    <div className={`image-preview-container ${open && 'active'}`} onClick={() => setOpen(false)}>
      <div style={{ backgroundImage: `url("${imagePath}")` }}></div>
    </div>
  )
}

export default ImagePreview
