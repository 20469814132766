import moment from 'moment'
import { trackingUrl } from './shippingHelpers'

export const getMFHPatientDetailFromLineItem = (line_item) => {
  var MFHPatient = {
    ProviderName: '',
    ProviderNameOther: '',
    VasectomyDate: '',
    PatientName: '',
    PatientDoB: '',
    NotificationMethod: '',
    KitID: '',
    RegistrationCode: ''
  }

  if (line_item.properties && line_item.properties.length > 0) {
    line_item.properties.forEach((prop) => {
      MFHPatient[prop.name] = prop.value
    })
  }

  return MFHPatient
}

export const formatMFHLineItemAndPatientDetail = (LineItem, MFHPatient) => {
  let Prov = MFHPatient.ProviderName
  let ppKitDisplay = null
  if (Prov.indexOf('Other') >= 0) {
    Prov = MFHPatient.ProviderNameOther + ' **'
  }

  if (LineItem.sku === 'MFHPPRK') {
    // Does this look like an 8-digit number?
    if (MFHPatient.KitID && MFHPatient.KitID.match(/\d{8}/)) {
      // Looks legit; let's link to the kit page for this KitID
      //   TODO: Fix the following link.
      ppKitDisplay = <a href={`#/kits/show/${MFHPatient.KitID}`}>{MFHPatient.KitID} </a>
    } else {
      // Show warning
      ppKitDisplay = (
        <p>
          {MFHPatient.KitID}{' '}
          <a href="#/">
            <span style={{ color: 'red' }}>
              <i className="fa fa-exclamation-circle"></i>
            </span>
          </a>
        </p>
      )
    }
  }

  return (
    <div style={{ background: '#D3F1FF', border: '1px solid #6CA8C5', paddingLeft: '4px' }}>
      {/* SKU */}
      <p>
        {LineItem.quantity} x {LineItem.sku}
      </p>
      {/* Patient */}
      <div>
        <p>
          <u>
            <b>Patient</b>
          </u>
        </p>
        <p>{MFHPatient.PatientName}</p>
        <p>{MFHPatient.PatientDoB}</p>
      </div>
      {/* Provider */}
      <div>
        <p>
          <u>
            <b>Provider</b>
          </u>
        </p>
        <p>{Prov}</p>
      </div>
      {/* Vas */}
      <div>
        <p>
          <u>
            <b>Vas Date</b>
          </u>
        </p>
        <p>{MFHPatient.VasectomyDate}</p>
      </div>
      {/* Notify */}
      <div>
        <p>
          <u>
            <b>Notify</b>
          </u>
        </p>
        <p>{MFHPatient.NotificationMethod}</p>
      </div>
      {ppKitDisplay && (
        <div style={{ background: '#e0f3e0', border: '1px solid #58c535' }}>
          <p>
            <u>
              <b>PrePaid KitID</b>
            </u>
          </p>
          {ppKitDisplay}
        </div>
      )}
    </div>
  )
}

export const getOutboundShippingInfo = (record) => {
  let output = []

  if (record.shippingDate) {
    output.push(<p>{moment(record.shippingDate).local().format('YYYY-MM-DD h:mmA z')}</p>)
  }

  // Tracking number
  if (parseInt(record.shippingTrackingNumber)) {
    output.push(<p>{record.shippingTrackingNumber}</p>)
  }

  // Label and Tracking Links
  if (record.shippingLabelUrl) {
    output.push(
      <a href={`${record.shippingLabelUrl}`} target="_blank" rel="noreferrer">
        <span className="fa-solid fa-file-pdf icon-color" aria-hidden="true"></span>&nbsp;PDF
      </a>
    )
  }

  if (record.shippingTrackingNumber) {
    // Use a fabricated USPS tracking URL ...
    let url = trackingUrl(record.shippingTrackingNumber)

    // ... unless we have an EasyPost tracking Url
    if (record.shippingTrackingUrl) {
      url = record.shippingTrackingUrl
    }

    if (url.length) {
      output.push(
        <a href={url} target="_blank" rel="noreferrer">
          <span className="fa-solid fa-truck icon-color" aria-hidden="true"></span>&nbsp;Track
        </a>
      )  
    }
  }

  return output
}

// Functions to validate if action buttons should show up
export const canAssignKits = (order, notify) => {
  let canAssignKits = false
  const validSKUs = ['MFHPPRK', 'MFHRK', 'MFHRK2']
  const lineItems = order.shopifyData.line_items

  lineItems.forEach((item) => {
    if (validSKUs.includes(item.sku)) {
      canAssignKits = true
    }
  })

  if (!canAssignKits) {
    return false
  }

  if (order.status === 'new') {
    switch (order.shopifyData.financial_status) {
      case 'paid':
        return true
      case 'pending':
        return true
      default:
        break
    }
  }

  return false
}

export const canAssignBulkKits = (order, notify) => {
  let canAssignKits = false
  const validSKUs = ['PPBULK10', 'PPBULK25', 'PPBULK50', 'PPBULK100']
  const lineItems = order.shopifyData.line_items

  lineItems.forEach((item) => {
    if (validSKUs.includes(item.sku)) {
      canAssignKits = true
    }
  })

  if (!canAssignKits) {
    return false
  }

  if (order.status === 'new') {
    switch (order.shopifyData.financial_status) {
      case 'paid':
        return true
      case 'pending':
        return true
      default:
        break
    }
  }

  return false
}

export const canShipViaShopify = (order, notify) => {

  /* Look for any other SKUs on this order ... if found, then
     we offer a link for the user to go to Shopify to create
     and ship this order */
  const adminHandledSKUs = ['MFHPPRK', 'MFHRK', 'MFHRK2', 'LTRP']
  const lineItems = order.shopifyData.line_items

  // If the order is already shipped, then don't allow shipping via Shopify button
  if ( order.status == 'shipped' ) {
    return false
  }

  // Get unique SKUs on this order
  const uniqueSKUs = lineItems.reduce((acc, item) => {
    if (!acc.includes(item.sku)) {
      acc.push(item.sku)
    }
    return acc
  }, [])
  
  // Filter out SKUs we ship in admin tool
  return uniqueSKUs.filter(sku => !adminHandledSKUs.includes(sku)).length > 0
}

export const canCreateAllShippingLabels = (order) => {
  return order.status === 'kitsAssignedReadyToShip' && !order.noOutboundShipping
}

export const isShippingLabelPurchased = (order) => {
  switch (order.status) {
    case 'shippingLabelPurchased':
      return true
    default:
      return false
  }
}

export const canMarkAsFulfilled = (order) => {
  // Allow "mark as fulfilled for:
  //
  //  (1) Orders w/o shipping, typically for a kit-registration
  //      order for SKU MFHPPRK that has already advanced to
  //      'kitsAssignedReadyToShip' or 'shippingLabelPurchased'
  //
  if (order.noOutboundShipping) {
    switch (order.status) {
      case 'kitsAssignedReadyToShip':
      case 'shippingLabelPurchased':
        return true
      default:
        break
    }
  }

  return false
}

export const canMarkAsManuallyFulfilled = (order) => {
  // Allow "mark as manually fulfilled" for:
  //
  //  Orders that have only these SKUs
  //    MFHREFCARDS
  //    MFHREFCARDS-50
  //    MFHREFCARDS-100
  //    MFHREFCARDS-250
  //    MFHWP
  //
  //  and are status "new"
  let canMarkasManuallyFulfilled = false

  const validSKUs = ['MFHREFCARDS', 'MFHREFCARDS-50', 'MFHREFCARDS-100', 'MFHREFCARDS-250', 'MFHWP']
  const lineItems = order.shopifyData.line_items
  lineItems.forEach((item) => {
    if (validSKUs.includes(item.sku)) {
      canMarkasManuallyFulfilled = true
    }
  })

  if (order.status === 'new') {
    return canMarkasManuallyFulfilled
  }

  return false
}
