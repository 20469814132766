import moment from 'moment'
import { Link, useNotify, useRecordContext, useRefresh } from 'react-admin'
import { useNavigate } from 'react-router-dom'
import {
  canGetRegLabel,
  canMarkAsNotifyComplete,
  canNotifyPatient,
  canNotifyProvider,
  canReNotifyPatient,
  canTest,
  createShippingLabel,
  getLabTestResultPdf,
  getRegLabelPdf,
  isPendingConfirmation,
  isStatus,
  isTestComplete,
  markAsNotifyComplete,
  notifyPatient,
  notifyProviderFax,
  notifyProviderManual,
  patientHasBeenNotified,
  providerHasBeenNotified,
  recordManualNotification,
  revertStatus,
  isKitPrepaid,
} from '../../../utils/progressKit'
import { defaultProviderId } from '../../../utils/providerUtils'
import { trackingUrl } from './shippingHelpers'

const labTestResultLabels = [
  {
    value: 'none',
    label: 'bg-default',
  },
  {
    value: 'NSS',
    label: 'bg-success',
  },
  {
    value: 'Positive',
    label: 'bg-danger',
  },
  {
    value: 'error',
    label: 'bg-warning',
  },
]

export const labTestResultChoices = [
  {
    value: 'none',
    label: 'Not Tested',
  },
  {
    value: 'NSS',
    label: 'NSS',
  },
  {
    value: 'Positive',
    label: 'Positive',
  },
  {
    value: 'error',
    label: 'Error',
  },
]
export const labTestSampleGradeChoices = [
  {
    value: 'NONE',
    label: 'NONE (0/hpf)',
    abbr: ''
  },
  {
    value: 'RARE',
    label: 'RARE (<1/hpf)',
    abbr: 'R'
  },
  {
    value: 'MODERATE',
    label: 'MODERATE (~1-10)',
    abbr: 'M'
  },
  {
    value: 'HIGH',
    label: 'HIGH (10+/hpf)',
    abbr: 'H'
  },
  {
    value: 'ERROR',
    label: 'ERROR (specimen/test error)',
    abbr: 'E'
  },
  {
    value: 'FEW',
    label: 'FEW (~1-5/hpf) - deprecated; do not use',
    abbr: 'F'
  },
  {
    value: 'MANY',
    label: 'MANY (5-10/hpf) - deprecated; do not use',
    appr: 'Ma'
  },
  {
    value: 'FAILEDVAS',
    label: 'FAILEDVAS - deprecated; do not use',
    abbr: 'F'
  },
]

export const labTestAdviceCodeChoices = [
  {
    value: 'acNotTested',
    label: 'acNotTested',
  },
  {
    value: 'acNSS',
    label: 'acNSS',
  },
  {
    value: 'acNSSRetest',
    label: 'acNSSRetest',
  },
  {
    value: 'acRetest4Week',
    label: 'acRetest4Week',
  },
  {
    value: 'acRetest6Week',
    label: 'acRetest6Week',
  },
  {
    value: 'acRetest8Week',
    label: 'acRetest8Week',
  },
  {
    value: 'acConsultProvider',
    label: 'acConsultProvider',
  },
  {
    value: 'acConsultProviderSpecial',
    label: 'acConsultProviderSpecial',
  },
  {
    value: 'acError',
    label: 'acError',
  },
]

export const fromTimestamp = function (v, e) {
  if (!v) {
    return null
  }
  var d = moment(v).local().format('LLLL')
  return d
}

export const formatKitShipping = (kit) => {
  let output = []

  if (kit.inboundShippingLabelUrl) {
    output.push(
      <a href={kit.inboundShippingLabelUrl} target="_blank" rel="noreferrer">
        <span
          className="fa-solid fa-file-pdf"
          style={{ color: '#3196F3' }}
          aria-hidden="true"
          title="Inbound Shipping Label"
        ></span>
      </a>
    )
  }

  // Tracking
  var trackUrl = ''
  if (kit.inboundShippingTrackingNumber) {
    // Use a fabricated tracking URL ...
    trackUrl = trackingUrl(kit.inboundShippingTrackingNumber)

    // ... unless we have an EasyPost tracking Url
    if (kit.inboundShippingTrackingUrl) {
      trackUrl = kit.inboundShippingTrackingUrl
    }

    if (trackUrl.length) {
      output.push(
        <a href={trackUrl} target="_blank" rel="noreferrer">
          <span className="fa-solid fa-truck" style={{ color: '#3196F3' }} aria-hidden="true" title="Track inbound shipment"></span>
        </a>
      )
    }

  }
  return (
    <>
      {output.map((o, index) => (
        <span style={{ color: 'rgb(50, 150, 243)', fontSize: '1rem', paddingRight: '4px' }} key={index}>
          {o}
        </span>
      ))}
    </>
  )
}

export const showRequisitionForKit = (kit) => {
  var testRequisition = kit['testRequisition']
  var req_authorizedBy = testRequisition ? testRequisition.authorizedBy : ''
  var req_requisitionDate = testRequisition ? testRequisition.requisitionDate : ''
  return (
    <div>
      <p>
        Req:{' '}
        <a
          href="##"
          title={
            req_authorizedBy
              ? `Requisition created by ${req_authorizedBy} on ${moment(req_requisitionDate).format('MMM D, YYYY')}`
              : 'No test requisition on file.'
          }
        >
          {req_authorizedBy ? (
            <span style={{ color: 'green' }}>
              <i className="fa-solid fa-thumbs-up"></i>{' '}
            </span>
          ) : (
            <span style={{ color: 'red' }}>
              <i className="fa-solid fa-circle-exclamation"></i>{' '}
            </span>
          )}
        </a>
      </p>
    </div>
  )
}

export const labTestResultLabel = (kit) => {
  var result = kit.labTestResult
  var grade = kit.labTestSampleGrade
  var l = <p className="label">Not Tested</p>
  var gradeAbbr = ''
  // Get the test grade abbreviation
  for (let v of labTestSampleGradeChoices) {
    if (v.value === grade) {
      gradeAbbr = v.abbr
      break
    }
  }
  for (let v of labTestResultLabels) {
    if (v.value === result) {
      l = <p className={`label ${v.label}`}>{result === 'none' ? 'Not Tested' : result}{gradeAbbr ? ` (${gradeAbbr})` : null}</p>
      break
    }
  }

  var labDetail = ''
  if (result) {
    labDetail += kit.labTestResult
    if (kit.labTestSampleGrade !== 'NONE') {
      labDetail += ' (' + kit.labTestSampleGrade + ')'
    }
    labDetail += ' ... '
    labDetail += (kit.labTestedAt ? fromTimestamp(kit.labTestedAt) : '') + ' by ' + (kit.labTestedBy ? kit.labTestedBy : 'unknown')
    return <div title={labDetail}>{l}</div>
  } else {
    return l
  }
}

export const weeksSinceVasectomyLabel = (kit) => {
  if (kit.weeksSinceVasectomy && kit.weeksSinceVasectomy > 0) {
    return (
      <p>
        <span className="label"> {kit.weeksSinceVasectomy}</span> weeks
      </p>
    )
  }
  return ''
}

export const adviceCodeLabel = (kit) => {
  if (kit.adviceCode) {
    return kit.adviceCode
  }
  return ''
}

export const patientPicturesField = (kit) => {
  const patientPictures = kit.patientPictures
  if (patientPictures) {
    const tooltip = 'Patient has access to lab result pictures.'
    return (
      <span style={{ color: '#7d3c98' }}>
        <i className="fa-solid fa-camera" title={tooltip}></i>
      </span>
    )
  }
  return <div></div>
}

export const patientNotificationDetails = (kit) => {
  // Show:
  //
  //  Emailed: x
  //  Texted: y
  //  Retrieved: z
  //
  // If the lab test is done, show additional highlighting if
  //  notification has not been sent
  //
  var tooltip = ''
  var cssClass = ''

  // Legacy values
  var patientNotifiedAt = kit.patientNotifiedAt
  // var patientNotifiedBy = kit.patientNotifiedBy
  // var patientNotifiedMethod = kit.patientNotifiedMethod
  var patientNotifiedLegacy = false

  // New details
  var patientNotifications = kit.patientNotifications
  var patientNotificationsCount = patientNotifications ? patientNotifications.length : 0

  // If patientNotifications property is not present, fallback to
  // legacy values to see if the patient has already been notified
  if (!patientNotifications) {
    if (patientNotifiedAt) {
      patientNotifiedLegacy = true
      patientNotificationsCount = 1
    }
  }

  var resultRetrievalLocked = kit.resultRetrievalLocked

  // Notification status
  //
  // Show if email/text has been sent
  // If lab test is done, but no notification has been made, indicate a warning
  // If the legacy notification was used (instead of direct link to secure retrieval site) indicate with unlocked-lock
  cssClass = 'text-default'
  if (patientNotifiedLegacy) {
    tooltip = '(Manual Notification) Patient/Provider notified manually via email/text ' + fromTimestamp(kit.patientNotifiedAt)
  } else if (patientNotificationsCount) {
    tooltip =
      '(Secure Link) Patient was sent results retrieval link ' +
      patientNotificationsCount +
      ' time(s). Last: ' +
      fromTimestamp(kit.patientNotifiedAt)
  } else {
    // Not yet notified
    tooltip = 'Patient has not yet been notified of results.'
  }

  var notificationDetail = []
  if (patientNotificationsCount && patientNotifiedLegacy) {
    // Show unlock
    notificationDetail.push(<i className="fa-solid fa-unlock"></i>)
  } else if (patientNotificationsCount) {
    // Show lock
    notificationDetail.push(<i className="fa-solid fa-lock"></i>)
  }

  notificationDetail.push(
    <span>
      <i className="fa-solid fa-envelope"></i> / <i className="fa-solid fa-phone"></i>
    </span>
  )

  // If any notification has been made, show check
  if (patientNotificationsCount) {
    cssClass = 'text-success'
    notificationDetail.push(<i className="fa-solid fa-check"></i>)
  } else {
    // If no notifications have been made, and the test is complete,
    //  show a warning
    if (kit.labTestedAt) {
      cssClass = 'text-danger'
      notificationDetail.push(<i className="fa-solid fa-circle-exclamation"></i>)
    }
  }

  // If multiple notifications have been made, show the badge
  if (patientNotificationsCount > 1) {
    notificationDetail.push(
      <span style={{ border: '1px solid', padding: '2px 6px' }} className="text-success">
        {patientNotificationsCount}
      </span>
    )
  }

  // Wrap the notificationDetail with a span that indicates color and has
  // a tooltip

  var notificationStatus = (
    <span className={cssClass} title={tooltip}>
      {notificationDetail}
    </span>
  )

  ///////////////////////////////////////////////////////////////////////////////////////
  // Result Retrieval Status - default status "not viewed" (eyeball with a slash)
  tooltip = 'Patient has not viewed results.'
  var retrievalStatus = (
    <span title={tooltip}>
      <i className="fa-sharp fa-solid fa-eye-slash"></i>
    </span>
  )

  var resultRetrievals = kit['resultRetrievals']

  // Get counts
  var retrievalSuccessCount = (resultRetrievals && resultRetrievals.retrievalSuccessCount) || 0
  var retrievalFailureCount = (resultRetrievals && resultRetrievals.retrievalFailureCount) || 0

  if (retrievalSuccessCount > 0) {
    // Results have been retrieved at least once
    tooltip = 'Patient successfully viewed results ' + retrievalSuccessCount + ' time(s).'
    retrievalStatus = (
      <span className="text-success" title={tooltip}>
        <i className="fa-solid fa-eye fa-lg"></i> <i className="fa-solid fa-check"></i>{' '}
        {retrievalSuccessCount > 1 ? <span style={{ border: '1px solid', padding: '2px 6px' }}>{retrievalSuccessCount}</span> : ''}{' '}
      </span>
    )
  } else if (retrievalFailureCount > 0) {
    // Results have not been successfully retrieved, and at least one
    // failed attempt was made
    tooltip = 'Patient has FAILED to retrieve results ' + retrievalFailureCount + ' time(s).'
    retrievalStatus = (
      <span className="text-danger" title={tooltip}>
        <i className="fa-solid fa-eye fa-lg"></i> <i className="fa-solid fa-exclamation-circle fa-lg"></i>{' '}
        {retrievalFailureCount > 1 ? <span style={{ border: '1px solid', padding: '2px 6px' }}>{retrievalFailureCount}</span> : ''}{' '}
      </span>
    )
  }

  // Show lock icon if result retrievals are locked.
  // Append to the end of the retrieval success/fail indicator
  if (resultRetrievalLocked) {
    tooltip = 'Result retrieval is locked. Go to kit details to unlock.'
    retrievalStatus = [
      retrievalStatus,
      <span title={tooltip}>
        <span className="label bg-warning">
          <i className="fa-solid fa-lock"></i>
        </span>
      </span>,
    ]
  }

  // Format result
  return (
    <div className="notification-icons">
      {notificationStatus}
      <div style={{ background: '#a0a0a0', height: '1px', marginTop: '4px', marginBottom: '4px' }}></div>
      {retrievalStatus}
      <div style={{ background: '#a0a0a0', height: '1px', marginTop: '4px', marginBottom: '4px' }}></div>
    </div>
  )
}

export const providerNotificationDetails = (kit) => {
  var notificationCount = 0
  if (kit.providerNotificationIds && Array.isArray(kit.providerNotificationIds)) {
    notificationCount = kit.providerNotificationIds.length
  }

  if (notificationCount > 0) {
    // Format
    var urlNotificationsByKitId = '#/notifications/list?search=' + encodeURI(JSON.stringify({ q: kit.kitId }))

    return (
      <a href={urlNotificationsByKitId}>
        <button className="btn btn-xs btn-success" type="button">
          Provider Notified {notificationCount > 1 ? <span className="label">{notificationCount}</span> : ''}{' '}
        </button>
      </a>
    )
  }

  return ''
}

export const KitActions = ({ handleModalOpen }) => {
  const record = useRecordContext()
  const notify = useNotify()
  const refresh = useRefresh()
  const navigate = useNavigate()
  return (
    <div className="kit-actions">
      {/* <!-- Non-action text. Informational --> */}

      {isStatus(['inboundShippingLabelCreated', 'inboundShippingLabelPurchased'], record) && (
        <p>
          <i>(Go to Order to Ship)</i>
        </p>
      )}

      {/* Vertical Button Group */}
      {/* Label Creation  */}
      <div>
        {isStatus('new', record) && (
          <button className="btn btn-default" onClick={() => createShippingLabel(record, notify, refresh)}>
            <span className="fa-solid fa-barcode"></span>&nbsp;Create Inbound Shipping Label
          </button>
        )}

        {/* Lab Test Button */}
        {canTest(record) && (
          <button className="btn btn-primary text-white" onClick={() => handleModalOpen(record)}>
            <span className="fa-solid fa-microscope" title="Record Test"></span>&nbsp;Record Test
          </button>
        )}

        {/* <!-- Lab Test Result PDF button --> */}
        {isTestComplete(record) && isStatus(['pendingConfirmation', 'testComplete'], record) && (
          <button className="btn btn-primary text-white" onClick={() => getLabTestResultPdf(record, notify)}>
            <span className="fa-solid fa-file-pdf" title="Test Result PDF"></span>&nbsp;View Test Result PDF
          </button>
        )}
        {/* Test Confirmation Button pendingConfirmation => testComplete */}
        {isPendingConfirmation(record) && (
          <button className="btn btn-success" onClick={() => revertStatus('testComplete', record, notify, refresh)}>
            <span className="fa-solid fa-check"></span>&nbsp;Confirm Test
          </button>
        )}
        {/* <!-- Patient Notify buttons --> */}
        {canNotifyPatient(record) && (
          <button className="btn btn-info" onClick={() => notifyPatient(undefined, [record], notify, refresh)}>
            <span className="fa-solid fa-link"></span>&nbsp;Send Results Link to Patient
          </button>
        )}

        {canNotifyPatient(record) && (
          <button className="btn btn-default" onClick={() => recordManualNotification(record, notify, refresh)}>
            <span className="fa-solid fa-phone"></span>&nbsp;Patient Has Been Notified (Manual)
          </button>
        )}

        {/* <!-- Patient re-notify --> */}
        {canReNotifyPatient(record) && (
          <button className="btn btn-info" onClick={() => notifyPatient(true, [record], notify, refresh)}>
            <span className="fa-solid fa-link"></span>&nbsp;Re-send Results Link to Patient
          </button>
        )}

        {/* <!-- Provider Notify buttons --> */}
        {canNotifyProvider(record) && (
          <button className="btn btn-warning" onClick={() => notifyProviderFax([record], notify, refresh)}>
            <span className="fa-solid fa-fax"></span>&nbsp;Fax Result to Provider
          </button>
        )}

        {canNotifyProvider(record) && (
          <button className="btn btn-default" onClick={() => notifyProviderManual(record, notify, refresh)}>
            <span className="fa-solid fa-phone"></span>&nbsp;Provider Has Been Notified (Manual)
          </button>
        )}

        {/* <!-- Status change buttons --> */}
        {canGetRegLabel(record) && (
          <button className="btn" onClick={() => getRegLabelPdf(record, notify)}>
            <span className="fa-solid fa-file"></span>&nbsp;Fetch registration label
          </button>
        )}
        {/* <!-- 
       testComplete => notifyComplete
       GREEN - patient and provider have been notified!
        --> */}
        {canMarkAsNotifyComplete(record) && patientHasBeenNotified(record) && providerHasBeenNotified(record) ? (
          <button className="btn btn-success" onClick={() => markAsNotifyComplete([record], notify, refresh)}>
            <span className="fa-solid fa-check"></span>&nbsp;Mark as <b>Notify Complete</b>
          </button>
        ) : null}

        {/* <!-- 
       testComplete => notifyComplete
       WHITE - patient and/or provider have NOT been notified!
        --> */}
        {canMarkAsNotifyComplete(record) && (!patientHasBeenNotified(record) || !providerHasBeenNotified(record)) && (
          <button className="btn btn-default" onClick={() => markAsNotifyComplete([record], notify, refresh)}>
            <span className="fa-solid fa-check"></span>&nbsp;Mark as <b>Notify Complete</b>
          </button>
        )}

        {/* <!--
       Move unregisteredPrepaid, outToPatient, shippedByPatient, deliveredToLab => manualReview
        --> */}
        {isStatus(
          ['unregisteredPrepaid', 'outToPatient', 'shippedByPatient', 'deliveredToLab', 'pendingConfirmation', 'testComplete'],
          record
        ) && (
          <span style={{ paddingTop: '2px' }}>
            <button className="btn btn-info" onClick={() => revertStatus('manualReview', record, notify, refresh)}>
              <span className="fa-solid fa-eye"></span>&nbsp;Needs <b>Manual Review</b>
            </button>
          </span>
        )}

        {/* <!--
       REVERT manualReview => deliveredToLab
        --> */}
        {isStatus('manualReview', record) && (
          <button className="btn btn-secondary" onClick={() => revertStatus('deliveredToLab', record, notify, refresh)}>
            <span className="fa-solid fa-backward-step"></span>&nbsp;Revert status to <b>Delivered To Lab</b>
          </button>
        )}

        {/* <!--
       REVERT manualReview => testComplete
        --> */}
        {isStatus('manualReview', record) && (
          <button className="btn btn-secondary" onClick={() => revertStatus('testComplete', record, notify, refresh)}>
            <span className="fa-solid fa-backward-step"></span>&nbsp;Revert status to <b>Test Complete</b>
          </button>
        )}
        {/* <!--
       Send manualReview => unregisteredPrepaid
        --> */}
        {isKitPrepaid('manualReview', record) && (
          <button className="btn btn-secondary" onClick={() => revertStatus('unregisteredPrepaid', record, notify, refresh)}>
            <span className="fa-solid fa-backward-step"></span>&nbsp;Revert status to <b>Unregistered Prepaid</b>
          </button>
        )}

        {/* <!--
       Send manualReview => error
        --> */}
        {isStatus('manualReview', record) && (
          <button className="btn btn-warning" onClick={() => revertStatus('error', record, notify, refresh)}>
            <span className="fa-solid fa-backward-step"></span>&nbsp;Set status to <b>Error</b>
          </button>
        )}

        {/* <!--
       REVERT notifyComplete => testComplete
        --> */}
        {isStatus('notifyComplete', record) && (
          <button className="btn btn-danger" onClick={() => revertStatus('testComplete', record, notify, refresh)}>
            <span className="fa-solid fa-backward-step"></span>&nbsp;Revert status to <b>Test Complete</b>
          </button>
        )}

        {/* <!--
       REVERT error => testComplete
  --> */}

        {isStatus('error', record) && (
          <button className="btn btn-danger" onClick={() => revertStatus('testComplete', record, notify, refresh)}>
            <span className="fa-solid fa-backward-step"></span>&nbsp;Revert status to <b>Test Complete</b>
          </button>
        )}

        {/* 
  <!--
       REVERT error => deliveredToLab
  --> */}

        {isStatus('error', record) && (
          <button className="btn btn-danger" onClick={() => revertStatus('deliveredToLab', record, notify, refresh)}>
            <span className="fa-solid fa-backward-step"></span>&nbsp;Revert status to <b>Delivered To Lab</b>
          </button>
        )}
        <br />

        {/* <!-- End of Vertical Button Group --> */}
      </div>
    </div>
  )
}

export const formatProviders = (provider, providerNameOther) => {
  if (!provider) {
    return 'Not Assigned'
  }
  var providerDetail = []
  var url = `/providers/${provider.providerId}/show`

  if (provider.providerId == defaultProviderId && providerNameOther) {
    return (
      <div className="otherProvider">
        <b>Other Provider:</b>
        <br />
        {providerNameOther}
      </div>
    )
  }

  // Provider Display Name (link)
  providerDetail.push(<Link to={url}>{provider.displayName}</Link>)

  // City, State
  if (provider.city) {
    providerDetail.push(
      <p>
        {provider.city} {provider.state && `,${provider.state}`}
      </p>
    )
  }

  // Fax #
  providerDetail.push(
    <p>
      <span className="fa-solid fa-fax" aria-hidden="true" title="Fax Number"></span>&nbsp;{provider.fax || '-'}
    </p>
  )

  // Fax prefs
  providerDetail.push(<p>Fax prefs:</p>)
  if (provider.faxNegativeResults === 'Yes' && provider.faxPositiveResults === 'Yes') {
    providerDetail.push(
      <p>
        <b>Positive</b> and <b>NSS</b>
      </p>
    )
  } else if (provider.faxNegativeResults === 'Yes') {
    providerDetail.push(
      <p>
        <b>NSS</b> only
      </p>
    )
  } else if (provider.faxPositiveResults === 'Yes') {
    providerDetail.push(
      <p>
        <b>Positive</b> only
      </p>
    )
  } else {
    providerDetail.push(<p>none</p>)
  }

  return providerDetail
}

export const ShopifyLineSummary = function (order) {
  // Summarize each line on the order
  if (!order) {
    return '-'
  }

  var line_items = order.shopifyData.line_items || []
  if (Array.isArray(line_items) && line_items.length) {
    return line_items.map(function (item) {
      return formatOrderLinePropsText(item)
    })
  } else {
    return '-'
  }
}

const formatOrderLinePropsText = function (LineItem) {
  var props = getLineExtraProps(LineItem)

  var Prov = props.ProviderName
  if (Prov.indexOf('Other') >= 0) {
    Prov = props.ProviderNameOther + ' **'
  }
  var LineItemDetail =
    '---------------------------\n' +
    LineItem.quantity +
    ' x ' +
    LineItem.sku +
    '\n' +
    'Patient: ' +
    props.PatientName +
    '\n' +
    'DoB: ' +
    props.PatientDoB +
    '\n' +
    'Provider: ' +
    Prov +
    '\n' +
    'Vas Date: ' +
    props.VasectomyDate +
    '\n' +
    'Notify: ' +
    props.NotificationMethod +
    '\n'

  // If this is a PrePaid Return Kit registration, get the KitID
  // entered by the customer
  if (LineItem.sku === 'MFHPPRK') {
    LineItemDetail += 'PrePaid KitID: ' + props.KitID + '\n'
  }

  return LineItemDetail
}

const getLineExtraProps = function (line_item) {
  var props = {
    ProviderName: '',
    ProviderNameOther: '',
    VasectomyDate: '',
    PatientName: '',
    PatientDoB: '',
    NotificationMethod: '',
    KitID: '' /* when registering a pre-paid kit */,
  }

  if (line_item.properties && line_item.properties.length > 0) {
    line_item.properties.map(function (prop) {
      props[prop.name] = prop.value
    })
  }

  return props
}
